<template>
  <div>
    <input
        id="assetsFieldHandle"
        ref="file"
        accept="image/png, image/gif, image/jpeg,image/jpg"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        name="fields[assetsFieldHandle][]"
        style="display: none"
        type="file"
        @change="uploadFile()"

    />
    <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer"
           for="assetsFieldHandle">
      <vue-feather style="color: #475661;cursor: pointer;" type="paperclip"/>
    </label>
  </div>
</template>

<script>
import {uploadMediaFile} from "@/services/utils/fileUploader";

export default {
  name: "FeedBackImageUpload",
  data(){
    return{
      showDragDiv: true,
      website: "",
      file: "",
      color: "",
      image_url: '',
    }
  },
  methods:{
    async handleImage(file) {
      const {
        key: feedback_image_url,
        name: feedback_image,
        size: feedback_image_file_size
      } = await uploadMediaFile(file, 'feedback')
      let imageInfo = {image_url: feedback_image_url, image_name: feedback_image, image_size: feedback_image_file_size};
      this.$emit('sendImage', {imageInfo,feedback_image_url});
    },

    getSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (Math.ceil(size / Math.pow(1024, i)).toFixed(2) * 1) +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },

    async uploadFile() {
      this.loading = true
      this.file = this.$refs.file.files[0];
      const exts = ['jpeg', 'png', 'gif', 'jpg']
      let splitFile = this.file.name.split(".")
      console.log(this.file)
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        console.log('d')
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.file = "";
        this.loading = false
        return
      }
      let size = this.getSize(this.file.size)
      if (size.split(' ')[1] === 'MB' ){
        this.$displaySnackbar({message: 'Maximum Image size is 1MB', success: false})
        this.file = "";
        this.loading = false
        this.showDragDiv = false;
        return
      }
      try {
        await this.handleImage(this.file);
        this.loading = false
      } catch (e) {
        this.loading = false;
        return;
      }
    },
  }
}
</script>

<style scoped>

</style>
