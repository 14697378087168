const colors = {
    enumRedWarning: "#FF2E2E",
    enumDarkBlue:"#001343",
    enumGrey1: "#1E323F",
    enumGrey3:'#9CABB5',
    enumGrey4:'#F7F9FC',
    white: "#FFF",
    enumPurple3: '#8F47F4',
    enumBlue:'#008EEF',
    enumCrispgreen:'#EBFAFC',
    enumCrispgreen2:'#0C8B99',
    enumYellow5:'#FFFCF0'
}

export default {
    enumWhite: {
        white: colors.white
    },
    enumDarkBlue: {
        enumDarkBlue: colors.enumDarkBlue
    },
    enumBlue:{
        enumBlue: colors.enumBlue
    } ,
    enumRedWarning:{
        enumRedWarning: colors.enumRedWarning
    },
    enumCrispgreen: {
        enumCrispgreen: colors.enumCrispgreen
    },
    enumCrispgreen2: {
        enumCrispgreen2: colors.enumCrispgreen2
    },

    enumYellow5: {
        enumYellow5: colors.enumYellow5
    }

    // $vuetify.theme.themes.light.semicolonBlue.blue
    // primary: colors.harshRed
}
