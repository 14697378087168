import { v4 as uuid } from 'uuid'
import handleLikeMixin from "@/mixins/handleLike.mixin";

export default {
    SET_JOB: (state, data) => {
        state.jobInfo = data
        setCriteria(state, data)
    },
    SET_CRITERIA: (state, data) => {
        state.criteriaDetails = data;
        if (data) {
            console.log(data)
            setCriteria(state, data)
        }
    },
    SET_OTHER_CRITERIA: (state, data) => {
        data = data.map(question => ({
            ...question,
            options: question.options.map(option => ({ id: uuid(), name: option, correct: question?.answer.includes(option) })),
            type: question.question_type,
            quiz_type: question.question_type,
            question: question.content
        }))
        state.otherCriterias = data
    }
};

function setCriteria(state, data) {
    let min_salary
    let max_salary
    if (data.minimum_salary) {
        min_salary = data.minimum_salary ? data.minimum_salary : 0
        max_salary = data.maximum_salary ? data.maximum_salary : 0
    } else {
        min_salary = data.salary_lower_limit ? Math.floor(data.salary_lower_limit) : 0
        max_salary = data.salary_upper_limit ? Math.floor(data.salary_upper_limit) : 0
    }
    //@todo optimise code

    // const [age,experience,education,location,certificate,salary] = state.fieldData
    // location.response.country = data.country
    // location.response.state = data.state
    // location.response.city = data.city
    // location.response.is_remote = data.is_remote
    // salary.response.currency = data.currency
    // salary.response.salary_range = handleLikeMixin.methods.convertNumber( min_salary) + ' - ' + handleLikeMixin.methods.convertNumber(max_salary)
    // salary.response.salary_lower_limit = min_salary
    // salary.response.salary_upper_limit = max_salary
    // experience.response.years_of_experience = data.years_of_experience
    // experience.response.experience = data.years_of_experience === 0 ? '<1' : data.years_of_experience < 4 ? '1-3' : data.years_of_experience < 8 ? '4-7' : '8+'
    // education.response.highest_level_of_education = data.highest_level_of_education
    // certificate.response.certification = data.certification
    // age.response.age_lower_limit = data.age_lower_limit
    // age.response.age_upper_limit = data.age_upper_limit

    state.fieldData[3].response.country = data.country
    state.fieldData[3].response.state = data.state
    state.fieldData[3].response.city = data.city
    state.fieldData[3].response.is_remote = data.is_remote
    state.fieldData[5].response.currency = data.currency
    state.fieldData[5].response.salary_range = handleLikeMixin.methods.convertNumber( min_salary) + ' - ' + handleLikeMixin.methods.convertNumber(max_salary)
    state.fieldData[5].response.salary_lower_limit = min_salary
    state.fieldData[5].response.salary_upper_limit = max_salary
    state.fieldData[1].response.years_of_experience = data.years_of_experience
    state.fieldData[1].response.experience = data.years_of_experience === 0 ? '<1' : data.years_of_experience < 4 ? '1-3' : data.years_of_experience < 8 ? '4-7' : '8+'
    state.fieldData[2].response.highest_level_of_education = data.highest_level_of_education
    state.fieldData[0].response.age_lower_limit = data.age_lower_limit
    state.fieldData[0].response.age_upper_limit = data.age_upper_limit
    const shortListingOptions = Object.keys(data).filter(key => key.startsWith('use_') && key !== 'use_skills')
    shortListingOptions.forEach(option => {
        const type = (function () {
            let split = option.split('_')
            split.shift()
            split = split.join('_')
            const types = {
                level: 'highest_level_of_education'
            }
            return types[split] ?? split
        })()
        const field = state.fieldData.find(item => item.type === type)
        field.enable = data[option]
    })
}
