export default {
    displaySnackBar({ commit }, payload) {

        commit("SHOW_SNACKBAR", payload);

    },
    closeSnackBar({ commit }) {
        commit("TOGGLE_SNACKBAR", false)
    },
    displayNetworkAlert({ commit }, payload) {

        commit("SHOW_NETWORK_ALERT", payload);

    },
};