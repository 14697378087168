<template>
  <v-app-bar color="white" fixed elevation="1" height="76">
    <div :class="[$route.name === 'LandingPage'? 'applyJustFlex tw-w-full ':'applyFlexBetween tw-w-full ']">
      <div class="tw-hidden lg:tw-block">
        <EnumWithWordIcon/>
      </div>
      <div class="lg:tw-hidden tw-mr-12">
       <slot name="route-back-component">
         <EnumWithoutWordIcon/>
       </slot>
      </div>
      <div v-if="$route.name !== 'InviteAnAdminView' && $route.name !==  'InterviewScheduleView'">
        <slot name="right-header-component">
          <base-button width="auto" outlined depressed
                       :button-text="$route.name === 'Job-stepOne' || $route.name === 'Job-stepTwo' ||$route.name === 'Job-stepThree'? 'Cancel': 'Save & Close'"
                       @click="$emit('cancel')"></base-button>
        </slot>
      </div>
    </div>
  </v-app-bar>
</template>
<script>

import EnumWithWordIcon from "@/components/UIComponents/reusablesIcon/EnumWithWordIcon";
import EnumWithoutWordIcon from "@/components/UIComponents/reusablesIcon/EnumWithoutWordIcon";
import BaseButton from "@/components/UIComponents/BaseButton";

export default {
  name: "Header",
  components: {BaseButton, EnumWithoutWordIcon, EnumWithWordIcon},
  data() {
    return {
      buttonText: 'Cancel',
      title: 'Cancel Job Posting',
      cancel: 'No',
      post: 'Yes',
      outline: true,
      subTitle: 'All current progress will be lost!'

    }
  },
  methods: {
    gotoDashboard() {
      this.$router.push({name: "RecruiterDashboard"})
      sessionStorage.removeItem('returnedSelectedDetails')
    }
  }
}
</script>

<style scoped>

.v-application .elevation-1 {
  box-shadow: 0px 2px 16px #F0F9FF !important;
}
</style>
