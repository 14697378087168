<template>
  <Onboardlayout>
    <template v-slot:header>
      <Header>
        <template v-slot:right-header-component>
        <span class="tw-text-enumGrey3 already">
        </span>

        </template>
      </Header>
    </template >

    <template v-slot:main-content>
      <div v-if="$route.query.error">
        <ErrorPage505/>
      </div>
      <div v-else>
        <ErrorPage404/>
      </div>

    </template>
  </Onboardlayout>
</template>

<script>
import ErrorPage404 from "@/components/errorPage/components/ErrorPages/ErrorPage404";
import Header from "@/components/onboarding/reuseables/Header";
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import ErrorPage505 from "@/components/errorPage/components/ErrorPages/ErrorPage505";

export default {
  name: "ErrorPageView",
  components: {ErrorPage505, ErrorPage404, Header, Onboardlayout}
}
</script>

<style scoped>

</style>