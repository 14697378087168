import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "@/services/api/axios"
import authHeader from "@/services/api/auth-header";

let TALENT_URL ;
let OPTS_URL ;

if (process.env.NODE_ENV === "development") {
    TALENT_URL = process.env.VUE_APP_DEV_TALENT_BASE_URL
    OPTS_URL = process.env.VUE_APP_DEV_OPTS_BASE_URL
} else {
    OPTS_URL = process.env.VUE_APP_PROD_OPTS_BASE_URL
    TALENT_URL = process.env.VUE_APP_PROD_TALENT_BASE_URL
}
const {
    POST,
    PUT,
     PATCH,
    DELETE
} = METHODS

export const sendOtherCriteria = data => {
    const url = '/corporate/shortlisting-criteria/create_other_shortlisting_criteria/'
    return callEndpoint({method:POST,url, data})
};
export const updateOtherCriteria = data => {
    const url = `/corporate/shortlisting-criteria/update_other_shortlisting_criteria_for_a_job/`
    return callEndpoint({method:PUT,url, data})
};
export const getOtherCriteria = data => {
    const url = `/corporate/shortlisting-criteria/get_other_shortlisting_criteria_for_job/?job_id=${data.id}&site_name=${data.site_name}`
    return callEndpoint({url})
};
export const checkSiteNameExist = site_name => {
    const url = `/corporate/company/check_site_name/?site_name=${site_name}`
    return callEndpoint({url})
}
export const deleteCriterias = data => {
    const url = `/corporate/shortlisting-criteria/delete_other_shortlisting_criteria_for_a_job/`
    return callEndpoint({method:DELETE,url, data})
};
export const sendCriteria = data => {
    const url = '/corporate/shortlisting-criteria/'
    return callEndpoint({method:POST,url, data})
};
export const updateCriteria = (data,id) => {
    const url = `/corporate/shortlisting-criteria/${id}/`
    return callEndpoint({method:PUT,url, data})
};
export const getCriteria = data => {
    const url = `/corporate/shortlisting-criteria/retrieve_shortlisting_criteria/?job_id=${data.id}&site_name=${data.site_name}`
    return callEndpoint({url})
};
export const getJobInfos = data => {
    const url = `/corporate/job/${data.id}/?site_name=${data.site_name}`
    return callEndpoint({url})
};
export const jobDetails = id => {
    const url = `/corporate/job/get_job_detail/?job_id=${id}`
    return callEndpoint({url})
};

export const sendInterviewInvite=(data)=>{
    const  url = `/interview/interview-invite/`
    return callEndpoint({method:POST,url,data})
}

export const resendInterviewInvite = (id) => {
    const url = `/interview/interview-invite/resend_interview_invite/?interview_id=${id}`
    return callEndpoint({url})
}

export const getAllSiteName = test => {
    const url = `/corporate/company/get_all_site_names/?site_name=${test}`
    return callEndpoint({url})
};
export const getAllCompanies = () => {
    const url = `/corporate/company/`
    return callEndpoint({url})
}

export const getCompanyProfile = (site_name) => {
    const url = `/corporate/company/get_company_summary/?site_name=${site_name}`
    return callEndpoint({url})
}
export const getAllAdminJobs = (siteName) => {
    const url = `/corporate/job/?site_name=${siteName}`
    return callEndpoint({url})
}
export const getAllDraftJobs = (siteName) => {
    const url = `/corporate/job/list_draft_jobs/?site_name=${siteName}`
    return callEndpoint({url})
}
export const getAllLiveJobs = (siteName) => {
    const url = `/corporate/job/list_live_jobs/?site_name=${siteName}`
    return callEndpoint({url})
}
export const getAllClosedJobs = (siteName) => {
    const url = `/corporate/job/list_closed_jobs/?site_name=${siteName}`
    return callEndpoint({url})
}
export const createCompanyProfile = (data) => {
    const url = `/corporate/company/`
    return callEndpoint({method:POST, url, data})
}
export const  getRecentJob =(site_name)=>{
    const  url = `/corporate/job/get_latest_job/?site_name=${site_name}`
    return callEndpoint({url})
}
export const getIndustriesAndRoles = (site_name) => {
    const  url = `/corporate/job/get_industries_and_roles/?site_name=${site_name}`;
    return callEndpoint({url});
}
export  const createJob = (data) => {
    const url = `/corporate/job/?site_name=${data.site_name}`;
    return callEndpoint({method: POST, url, data});
}
export const updateJob = (jobId, data) => {
    const url = `/corporate/job/${jobId}/?site_name=${data.site_name}`;
    return callEndpoint({method: PATCH, url, data})
}
export const getJobById = (data)=>{
    const url = `/corporate/job/${data.id}/?site_name=${data.site_name}`
    return callEndpoint({url})
}
export const updateJobById = (id, data) =>{
    const url = `/corporate/job/${id}/?site_name=${data.site_name}`
    return callEndpoint({method:PATCH,url,data})
}
export const getSkillsAndFramework = (data) => {
    const url = `/corporate/job/get_job_skills_and_frameworks/?site_name=${data.site_name}`
    return callEndpoint({method:POST,url,data})
}

// export  const getJobSkillsAndFrameworks =(role) =>{
//     const url = `/corporate/job/get_job_skills_and_frameworks/?site_name=${site_name}`
//     return callEndpoint({url,role})
// }
export  const  getJobMetrics=(site_name)=>{
    const url = `/corporate/job/company_job_metrics/?site_name=${site_name}`
    return callEndpoint({url})
}
export  const  getDashboardInsight=(site_name)=>{
    const url = `/corporate/job/get_company_dashboard_insight/?site_name=${site_name}`
    return callEndpoint({url})
}
export const uploadToCloudinary = async data => {
    return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
export const uploadCompanyMedia = (site_name, data) => {
    const url = `/corporate/company/upload_company_image_gallery/?site_name=${site_name}`
    return callEndpoint({method:PATCH,url,data})
};
export const uploadCompanyCoverImage = (site_name, data) => {
    const url = `/corporate/company/company_cover_image/?site_name=${site_name}`
    return callEndpoint({method:PATCH,url,data})
};
export const removeImage = (site_name, data) => {
    const url = `/corporate/company/remove_image_from_company_gallery/?site_name=${site_name}`
    return callEndpoint({method:PATCH,url,data})
};
export  const  getJobInfo = async jobID =>{
    const url = `/corporate/job/${jobID.id}/`
    return callEndpoint({url})
}
export  const  deleteJob = async jobID =>{
    const url = `/corporate/job/${jobID}/`
    return callEndpoint({method:DELETE, url})
}
export  const  getJobDescription = async (level, job_title) =>{
    const url = `/corporate/template/get_job_template/?level=${level}&job_title=${job_title}`
    return callEndpoint({url})
}
export const postJob = async job_id =>{
    const url = `/corporate/job/publish_job/?job_id=${job_id}`
    return callEndpoint({url})
}
export  const getUserProfile = async (site_name,job_id,email) => {
    const url = `/recruitment/application/get_application_summary/?site_name=${site_name}&job_id=${job_id}&email=${email}`
    return axios.get( TALENT_URL + url , {headers: authHeader()})
}
export const giveFeedBack = (data) => {
    const url = '/operations/feedback/'
    return axios.post( OPTS_URL + url , data,{headers: authHeader()})
}
export const userGetFeedBack = (data) => {
    const url = `/operations/feedback/get_comment_by_email/?email=${data.email}`
    return axios.get( OPTS_URL + url ,{headers: authHeader()})
}

// Evaluation Criteria
const CRITERIA_COUNTRY_URL = 'https://countriesnow.space/api/v0.1/countries/states'
export const getCriteriaCountries = async () => {
    return axios.get(CRITERIA_COUNTRY_URL)
}
export const createInterviewCriteria = async (data) =>{
    console.log(data)
    const url = '/interview/interview-criteria/'
    return callEndpoint({method:POST,url,data})
}

export const editCompanyProfile =(data, site_name) => {
    const url = `/corporate/company/edit_company_profile/?site_name=${site_name}`
    return callEndpoint({method: PATCH, url, data})
}
export const getReviews = async id => {
    const url = `/corporate/review/?company_id=${id}&page_size=5`
    return callEndpoint({ url})
}

export const getReviewInsight = async id => {
    const url = `/corporate/review/get_rating?company_id=${id}`
    return callEndpoint({ url})
}
export const getReviewsPaginated = async data => {
    const url = `/corporate/review/?company_id=${data.id}&page=${data.page_num}&page_size=5`
    return callEndpoint({ url})
}
export const getAllCriteria = async (data) =>{
    console.log( "the site name")
    const url = `/interview/interview-criteria?site_name=${data.site_name}&job_id=${data.id}`
    return callEndpoint({url})
}

export const deleteCriteria = async (data) =>{
    const url = `/interview/interview-criteria/${data.id}/?site_name=${data.site_name}`
    return callEndpoint({method:DELETE,url})
}
export const getCriteriaById = async (data) =>{
    const url = `/interview/interview-criteria/${data.id}/?site_name=${data.site_name}&job_id=${data.job_id}`
    return callEndpoint({url})
}
export const updateCriteriaById = async (data) =>{
    const url = `/interview/interview-criteria/${data.id}/`
    return callEndpoint({method:PUT,url,data})
}

export const addInterviewCriteriaToJob = async (data) =>{
    const url = `/interview/interview-criteria/add_remove_interview_criteria_to_job/`
    return callEndpoint({method:PUT,url,data})
}

export const getScreeningCriteria = async type => {
    const url = `/interview/screening/?criteria_type=${type}`
    return callEndpoint({url})
}

export const fetchCriteriaTopics = async type => {
    const url = `/interview/topic/?criteria_id=${type}`
    return callEndpoint({url})
}

export const fetchCriteriaTopicQuestions = async topicId => {
    const url = `/interview/question/?topic_id=${topicId}`
    return callEndpoint({url})
}

export const setScreeningQuestionForJob = async (topic_id, job_id) => {
    const url = `/interview/topic/add_remove_topic_to_job/`
    return callEndpoint({method: PUT, url, data: {topic_id, job_id}})
}
export const createTalentPool = async (data) =>{
    const url = '/corporate/talent-pool/'
    return callEndpoint({method:POST,url,data})
}
export const getAllTalentPool = async siteName => {
    const url = `/corporate/talent-pool/?site_name=${siteName}`
    return callEndpoint({url})
}

export const getSavedTestForJob = async (job_id) => {
    const url = `/interview/topic/get_saved_topics_for_job/?job_id=${job_id}`
    return callEndpoint({url})
}

export const getCompanyAdmins = async (site_name) => {
    const url = `/corporate/company/get_company_admins/?site_name=${site_name}`
    return callEndpoint({url})
}
export  const listAllCandidates = async (data) => {
    const url = `/recruitment/application/?job_id=${data.jobId}&site_name=${data.site_name}`
    return axios.get( TALENT_URL + url , {headers: authHeader()})
}

export const inviteCompanyAdmin = async (site_name, data) => {
    const url = `/corporate/company/invite_admin/?site_name=${site_name}`
    return callEndpoint({method: PATCH, url, data})
}
// Get countries and their states
const COUNTRY_BASE_URL = 'https://restcountries.com/v3.1/all'
export const getAllCountries = async  () =>{
    return axios.get(COUNTRY_BASE_URL)
}
const COUNTRY_URL = 'https://api.countrystatecity.in/v1/countries'
const API_KEY = 'M1U2OGtlUFFreXBZTnRCMDRnZ1NHVno2eWFhNHhKZGZuU3hoWTQzTA== '
export const getCountries = async  () =>{
    return axios.get(COUNTRY_URL, {headers: {'X-CSCAPI-KEY': API_KEY}})
}
export const getStates = async  (data) =>{
    const url = `https://api.countrystatecity.in/v1/countries/${data}/states`
    return axios.get(url, {headers: {'X-CSCAPI-KEY': API_KEY}})
}

export const makeOffer = async (data) => {
    const url = `/corporate/make_offer/`
    return callEndpoint({method:POST, url, data})
}
export const getAllCandidatesInATalentPool = async ({ site_name,pool_id}) => {
    const url = `/corporate/talent-pool/get_candidates_in_talent_pool/?site_name=${site_name}&pool_id=${pool_id}`
    return callEndpoint({url})
}
export const uploadEmailsToATalentPool = async (data) => {
    const url = `/corporate/talent-pool/upload_candidates_to_talent_pool/`
    return callEndpoint({method: PUT, url, data})
}
export const moveCandidateToATalentPool = async (data) => {
    const url = `/corporate/talent-pool/move_candidate_to_talent_pool/`
    return callEndpoint({method: PUT, url, data})
}
export const addCandidateToATalentPool = async (data) => {
    const url = `/corporate/talent-pool/add_candidate_to_talent_pool/`
    return callEndpoint({method: PUT, url, data})
}

export const rejectOffer = async (data) => {
    const url = `/corporate/reject/`
    return callEndpoint({method: POST, url, data})
}

export const getAllCandidatesForAJob = async (site_name) =>{
    const url = `/recruitment/applicant-pool/get_all_qualified_candidate_that_have_applied_to_a_company/?site_name=${site_name}`
    return callEndpoint({url},TALENT_URL)
}
export const weeklyInsights = async (site_name) =>{
    const url = `/thirdparty/applications/get_company_dashboard_graph_in_weeks/?site_name=${site_name}`
    return callEndpoint({url},TALENT_URL)
}
export const monthlyInsights = async (site_name) =>{
    const url = `/thirdparty/applications/get_company_dashboard_graph_in_months/?site_name=${site_name}`
    return callEndpoint({url},TALENT_URL)
}


export const getInterviewEvaluationCriteria=(jobId,interview)=>{
    const url = `/interview/interview-criteria/get_job_interview_criteria/?job_id=${jobId}&interview=${interview}`
    return callEndpoint({url})
}
export const getInterviewStatus=(email,jobId)=>{
    const url = `/interview/interview-invite/get_user_interview_status/?email=${email}&job_id=${jobId}`
    return callEndpoint({url})
}
export const sendInterviewComment=(data)=>{
    const url = `/interview/interview_comment/`
    return callEndpoint({method:POST,url,data})
}
export const sendWaitingList=(data)=>{
    const url = `/api/user/wait-list/`
    return callEndpoint({method:POST,url,data})
}
export const getInterviewComment=(jobId)=>{
    const url = `/interview/interview_comment/?interview_id=${jobId}`
    return callEndpoint({url,jobId})
}
export const submitInterviewGrade=(data)=>{
    const url = `/interview/evaluation/`
    return callEndpoint({method:POST,url,data})
}
export const inviteAdminForInterview=(data)=>{
    const url = `/interview/interview-invite/invite_admin_to_interview/?interview_id=${data.id}`
    return callEndpoint({method:POST,url,data})
}
export const getUserInterviewDetails=(id)=>{
    const url = `/interview/interview-invite/get_interview_detail/?interview_id=${id}`
    return callEndpoint({url,id})
}
export const startInterview =(data) => {
    const url = `/interview/interview-invite/start_interview/`
    return callEndpoint({method: PATCH, url, data})
}
export const getOffer = async (data) => {
    const url = `/corporate/make_offer/retrieve_offer/?job_id=${data.job_id}&email=${data.email}&site_name=${data.site_name}`
    return callEndpoint({url})
}

export const getInterviewSummary=(email,id)=>{
    const url = `/interview/interview-invite/?email=${email}&job_id=${id}`
    return callEndpoint({url,email})
}
export const nonExistingAdminAccount = (data) => {
    const url = `/api/user/profile/create_admin_profile/?site_name=${data.siteName}`
    return callEndpoint({method: PATCH, url,data})
}

export const closeJobPosting =(data) => {
    const url = `/corporate/job/close_job/?site_name=${data.site_name}&job_id=${data.jobId}`
    return callEndpoint({method:PATCH,url})
}
export const  getInterviewReportByAdmin=(id,email)=>{
    const url = `/interview/evaluation/?interview_id=${id}&email=${email}`
    return callEndpoint({url,id,email})
}
export const  getInterviewReportByCriteria=(id,email)=>{
    const url = `/interview/evaluation/group_score_by_criteria/?interview_id=${id}&email=${email}`
    return callEndpoint({url,id,email})
}
export  const  getAllAdminInterviewComment=(id,email)=>{
    const  url = `/interview/interview_comment/all_admin_interview_comments/?interview_id=${id}&candidate_email=${email}`
    return callEndpoint({url,id,email})
}
export  const  getInterviewRecommendQuestion=(id)=>{
    const  url = `/interview/interview-criteria-question/get_interview_recommended_questions?job_id=${id}`
    return callEndpoint({url,id})
}
export  const  getActivityLog=(email,job_id)=>{
    const  url = `/corporate/activity-log/?email=${email}&job_id=${job_id}`
    return callEndpoint({url,email,job_id})
}
export const getScreeningStatus=(id)=>{
    const url = `/corporate/screening-test/get_screening_categories_status/?job_id=${id}`
    return callEndpoint({url,id})
}
export const getSettingsStatus=(id)=>{
    const url = `/corporate/program-settings/${id}`
    return callEndpoint({url,id})
}
export const updateSettingsStatus=(program,data)=>{
    const url = `/corporate/program-settings/${program.id}/`
    return callEndpoint({method: PUT,url,data})
}
export const getQuestionAreaBySkillType = (data)=>{
    const url = `/corporate/screening-test/get_question_areas_for_job_test/?category=${data.category}&job_id=${data.job_id}`
    return callEndpoint({url,data})
}
export const getQuestionAreaForProg = (data)=>{
    const url = `/corporate/program-settings/get_question_areas_for_program_test/?program_id=${data.job_id}&category=${data.category}`
    return callEndpoint({url,data})
}
export const previewQuestionAreaById=(data)=>{
    const url = `/corporate/screening-test/get_sample_questions_for_question_area_for_test/?category=${data.category}&question_area_id=${data.question_area_id}&job_id=${data.job_id}`
    return callEndpoint({url,data})
}
export const previewQuestionAreaByProgramId=(data)=>{
    const url = `/corporate/screening-test/get_sample_questions_for_question_area_for_test/?category=${data.category}&question_area_id=${data.question_area_id}&program_id=${data.job_id}`
    return callEndpoint({url,data})
}
export const addScreeningTestQuestionArea=(data)=>{
    const url = `/corporate/screening-test/add_question_areas_to_screening_test/`
    return callEndpoint({method:POST,url,data})
}
export const addAssessmentQuestionArea=(data)=>{
    const url = `/corporate/program-settings/add_assessment_to_program/`
    return callEndpoint({method:POST,url,data})
}
export const setTestForJob=(data)=>{
    const url = `/corporate/screening-test/set_screening_test/`
    return callEndpoint({method:PUT,url,data})
}
export const getUserFirstLogin=()=>{
    const url = `/api/user/profile/get_app_user_info/`
    return callEndpoint({url})
}
export const getCompanyDescriptionAndMedia = async site_name => {
    const url = `/corporate/company/get_company_profile/?site_name=${site_name}`
    return callEndpoint({url})
}
export const getCompanyFaqs = async site_name => {
    const url = `/corporate/faqs/?site_name=${site_name}`
    return callEndpoint({url})
}
export const updateSingleCompanyFaq = async ({faq_id, data}) => {
    const url = `/corporate/faqs/${faq_id}/`
    return callEndpoint({method:PUT,url,data})
}
export const deleteCompanyFaq = async (faq_id) => {
    const url = `/corporate/faqs/${faq_id}/`
    return callEndpoint({method:DELETE,url})
}
export const createFAQ = ({site_name,data})=>{
    const url = `/corporate/faqs/?site_name=${site_name}`
    return callEndpoint({method:POST,url,data})
}
export const getCompanyAwards = async site_name => {
    const url = `/corporate/award/?site_name=${site_name}`
    return callEndpoint({url})
}
export const createAward = ({site_name,data})=>{
    const url = `/corporate/award/?site_name=${site_name}`
    return callEndpoint({method:POST,url,data})
}
export const updateSingleCompanyAward = async ({award_id, data}) => {
    const url = `/corporate/award/${award_id}/`
    return callEndpoint({method:PUT,url,data})
}
export const deleteCompanyAward = async (award_id) => {
    const url = `/corporate/award/${award_id}/`
    return callEndpoint({method:DELETE,url})
}
export const getCandidateReport=(email,id) => {
     const url = `/recruitment/report/candidate_report/?email=${email}&job_id=${id}`
    return axios.get( TALENT_URL + url , {headers: authHeader()})
}
export const getCandidateProviderReport=(site_name, email,id) => {
    const url = `/classroom/report/?site_name=${site_name}&application_id=${id}&email=${email}`
    return callEndpoint({url})
}

export const getCandidateCv = async (data) =>{
    const url = `/recruitment/application/candidate_cv/?email=${data.email}&job_id=${data.jobId}`
    return callEndpoint({url},TALENT_URL)
}

export const getJobSummary = async (data) =>{
    const url = `/corporate/job/get_job_summary/?role=${data.role}&level=${data.level}&site_name=${data.site_name}`
    return callEndpoint({url})
}

export const getExperienceLevels = async  (role) =>{
    const url = `/operations/job-templates/get_experience_levels_for_job_role/?job_role=${role}`
    return callEndpoint({url},OPTS_URL)
}

export const getSalaryRange = async (data) =>{
    const url =`/thirdparty/jobs/get_job_role_salary_range/?experience_level=${data.experience_level}&job_role=${data.role}`
    return callEndpoint({url},OPTS_URL)
}
export const skipScreeningTest = async (id)=>{
    const url = `/corporate/screening-test/skip_screening_test/?job_id=${id}`
    return callEndpoint({ method:POST,url,id})
}
export const getRejectionDetails = async (id,email)=>{
    const url = `/corporate/reject/get_rejection_detail/?job_id=${id}&email=${email}`
    return callEndpoint({ url,id,email })
}
export const getUserProgramInterviewDetails=(payload)=>{
    const url = `/interview/program-interview/interview_status/?site_name=${payload.site_name}&applicant_email=${payload.email}&applicant_id=${payload.id}`
    return callEndpoint({url, payload})
}
export const offerProgramAdmission=(data)=>{
    const url = `/classroom/offer/`
    return callEndpoint({method:POST,url,data})
}
export const getProgramInterviewQuestion=(payload)=>{
    const url = `/interview/interview-question/?site_name=${payload.site_name}&program_id=${payload.program_id}`
    return callEndpoint({url,payload})
}
export const getProgramInterviewComment=(payload)=>{
    const url = `/interview/program-interview-comment/?question=${payload.question}&interview=${payload.interview}&site_name=${payload.site_name}`
    return callEndpoint({url,payload})
}
export const postProgramInterviewComment=(data)=>{
    const url = `/interview/program-interview-comment/`
    return callEndpoint({method:POST,url,data})
}
export const getAllCohort=(site,id)=>{
    const url = `/classroom/offer/cohorts/?site_name=${site}&program_id=${id}`
    return callEndpoint({url,site,id})
}
export const assignApplicantToCohort=(data)=>{
    const url = `/classroom/offer/add_applicant_to_cohort/`
    return callEndpoint({method:POST,url,data})
}
export const getProgramApplicationStatus=(id,email)=>{
    const url = `/classroom/report/application_status/?program_applicant_id=${id}&program_applicant_email=${email}`
    return callEndpoint({url,id,email})
}
export const getQuestionnaireResponseStatus=(id)=>{
    const url = `/classroom/program-questionnaire/check_if_applicant_submitted_questionnaire/?program_id=${id}`
    return callEndpoint({url})
}
export const getQuestionnaireResponse=(id,applicant_email,site_name)=>{
    const url = `/classroom/program-questionnaire/get_questionnaire_response/?program_id=${id}&applicant_email=${applicant_email}&site_name=${site_name}`
    return callEndpoint({url})
}
export const getEnrollmentResponse=(id,applicant_email,site_name)=>{
    const url = `/classroom/program-enrollment-form/get_enrollment_form_response/?program_id=${id}&applicant_email=${applicant_email}&site_name=${site_name}`
    return callEndpoint({url})
}
export const getExternalSkills = (site_name, id)=>{
    const url =`/corporate/job/get_external_tests/?site_name=${site_name}&job_id=${id}`
    return callEndpoint({url,id,site_name})
}
export const startProgramInterview=(data)=>{
    const url= `/interview/program-interview-session/start_interview/`
    return callEndpoint({method:POST, url, data})
}
export const createInterview=(data)=>{
    const url = `/interview/program-interview/`
    return callEndpoint({method:POST, url, data})
}
export const endInterview = (data)=>{
    const url =`/interview/program-interview-session/end_interview/`
    return callEndpoint({method:PATCH, url,data})
}
export const getRecommendation =(payload)=>{
    const url=`/interview/program-interview-recommendation/?site_name=${payload.site_name}&email=${payload.email}&interview=${payload.interview}`
    return callEndpoint({url,payload})
}
export const createInteractionComment = (data)=>{
    const url=`/classroom/application_comment/`
    return callEndpoint({method: POST, url, data})
}
export const getAllInteractionComment = (payload)=>{
    const url=`/classroom/application_comment/?application=${payload.application}&site_name=${payload.site_name}`
    return callEndpoint({url,payload})
}
export const deleteComment = (data)=>{
    const url = `/classroom/application_comment/${data.id}/?application=${data.application}&site_name=${data.site_name}`
    return callEndpoint({method: DELETE,url,data})
}
export const getPayment = (payload) =>{
    const url=`/classroom/payment/payment_detail/?site_name=${payload.site_name}&applicant_id=${payload.applicant_id}`
    return callEndpoint({url,payload})
}
export const confirmPaymentDetails =(payload, data)=>{
    const url =`/classroom/payment/confirm/?site_name=${payload.site_name}&applicant_id=${payload.applicant_id}&detail=${payload.detail}`
    return callEndpoint({method:PATCH, url, data})
}
export const rejectPayment =(payload) =>{
    const url =`/classroom/payment/reject/?site_name=${payload.site_name}&applicant_id=${payload.applicant_id}&detail=${payload.detail}`
    return callEndpoint({method:PATCH, url, payload})
}
export const getApplicationActivityLog = (site_name, applicant_id) =>{
    const url = `/classroom/activity_log/?site_name=${site_name}&application_id=${applicant_id}`
    return callEndpoint({url,site_name,applicant_id})
}
