import {getJobInfo, getJobDescription} from "@/services/api/APIService";


export default {
    getAllJobInfo({commit}, data){
        return getJobInfo(data).then(
            res => {
                commit("setJobInfo", res.data);
                return Promise.resolve(res.data);
            }
        )
    },
    getAllJobDescription({commit}, payload){
        return getJobDescription(payload.level, payload.job_title).then(
            res => {
                commit("setJobDescription", res.data);
                return Promise.resolve(res.data);
            }
        )
            .catch(err =>{
                this.loading = false
                console.log(err.response.data.detail)
                if (err.response.data.detail.startsWith('Job')){
                    commit("setJobDescription", {});
                }
                this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
    }

}