import {viewFile} from "@/services/storage/aws";

export default {
    setEditCompanyProfile: (state, data) => {
        state.companyProfile = data
    },
    setCompanyProfile: (state, data) => {
        console.log({data}, 'profile')
        state.companyProfile = data
    },
    SET_REVIEW: (state, data) => {
        state.reviews = data
    },
    SET_REVIEW_INSIGHT: (state, data) =>{
        state.ratingInsight = data
    },
    setCompanyAdmin: (state, data) => {
        state.companyAdmin = data
    },
    getDescriptionAndMediaSuccess: async (state, data) =>{
        console.log(data, data.cover_image)
        viewFile({ key: data.cover_image, context: data, property: 'cover_image'})
        const mediaObjects = data.media_gallery.map(url => ({url}))
        mediaObjects.forEach(obj => viewFile({ url: obj.url, context: obj }))
        data.media_gallery = mediaObjects
        state.descriptionAndMedia = data
    },
    setMedia:(state, data) => {
        state.descriptionAndMedia.media_gallery = [data,...state.descriptionAndMedia.media_gallery]
    },
    setCoverImage:(state, data) =>{
        console.log({data})
        viewFile({ url: data, context: state.descriptionAndMedia, property: 'cover_image' })
    },
    SET_LOADING:(state,status)=>state.loading=status,
    getCompanyFaqsSuccess:(state, data) =>{
        state.faqs = data
    },
    updateFAQ:(state, data) =>{
        state.faqs.unshift(data)
    },
    updateSingleFaqSuccess:(state, data) =>{
         let index = state.faqs.findIndex(item=> item.id === data.id)
        console.log( index)
        let newArray = state.faqs
        console.log(newArray)
    },
    deleteCompanyFaqSuccess:(state, data) =>{
        state.faqs = state.faqs.filter(item=> item.id !== data)
        console.log(state.faqs)
    },
    getCompanyAwardsSuccess:(state, data) =>{
        state.awards = data
    },
    updateAward:(state, data) =>{
        state.awards.unshift(data)
    },
    deleteCompanyAwardSuccess:(state, data) =>{
        state.awards = state.awards.filter(item=> item.id !== data)
        console.log(state.awards)
    },
    updateSingleCompanyAwardSuccess:(state, data) =>{
        let index = state.awards.findIndex(item=> item.id === data.id)
        console.log( index)
        let newArray = state.awards
        console.log(newArray)
    },

}