<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 6.5L9 17.5L4 12.5" stroke="#00C595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "CheckIconGreen"
}
</script>

<style scoped>

</style>