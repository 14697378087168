export default {
    getUser_Profile: state => state.userProfile,
    getMakeOffer: state => state.makeOffer,
    getRejectOffer: state => state.rejectOffer,
    get_Offer_State:state => state.get_Offer_state,
    get_questionnaire_responses:state => state.questionnaire_response,
    get_enrollment_responses:state => state.questionnaire_response,
    getPaymentDetails:state => state.paymentDetails,
    get_allInteractionComment:state => state.allInteractionComment,
    // get_activity_log: state => state.activity_log
}
