<template>
  <div>
    <div class="md:tw-mx-14 md:tw-mt-10 tw-mx-5">
      <span class="head">Ease, Optimize and Automate Your Hiring Process</span>
    </div>
    <div class="md:tw-mx-14 mt-3 tw-mx-5">
      <span class="sub-head">Seamlessly solve your pressing recruitment needs in just a few clicks.</span>
    </div>
    <div class="md:tw-mx-9 md:tw-mt-20 tw-mt-10 md:tw-mb-40 tw-mb-10">
      <div class="mx-0 row tw-flex-row">
        <div class="col-12 col-md-4 tw-flex" v-for="(item, index) in details" :key="index">
          <div class="card tw-px-9 tw-pt-9 md:tw-pb-32 tw-pb-20 mx-2">
            <div>
              <v-img width="50px" :src="item.image"></v-img>
            </div>
            <div class="mt-3">
              <span class="tit">{{item.title}}</span>
            </div>
            <div class="mt-4">
              <span class="sub-tit">{{item.sub}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPageOptimize",
  data() {
    return {
      details: [
        {
          title: 'Collaborative \n' +
              'Hiring',
          image: require('@/assets/users.svg'),
          sub: 'Sync your team & processes, work together with your recruiting team, from different devices, to jointly carry out interview sessions and centralize information.'
        },
        {
          title: 'Shorter\n' +
              'Screening Time',
          image: require('@/assets/short.svg'),
          sub: 'Streamline & automate your most important hiring processes, thereby saving you 4X less screening time than what it takes other recruitment options.'
        },
        {
          title: 'Manage Recruitment on Mobile',
          image: require('@/assets/manage.svg'),
          sub: 'Keep up with your recruitment activities from your mobile device, schedule interviews, provide feedback, communicate with candidates, anywhere and anytime.'

        }
      ]

    }
  }
}
</script>

<style scoped>
.head {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.03em;

  color: #1E323F;
}
.sub-head{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 141%;

  color: #3F3F3F;

}
.card {
  background: #F0F8FD;
  box-shadow: 0px 4px 14px #EEF8FF;
  border-radius: 16px;
}
.tit{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -0.03em;

  color: #008EEF;
}
.sub-tit{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;

  color: #0B3453;
}
</style>