import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLodash from "vue-lodash";
import lodash from 'lodash'
import "./assets/css/tailwind.css"
import "./assets/css/global.scss"
import { ValidationObserver, ValidationProvider, extend, localize, setInteractionMode } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import displaySnackbar from "../src/services/utils/snackbar";
import displayNetworkAlert from "../src/services/utils/networkAlert"
import getSiteName from '@/services/utils/index'
import VueMeta from 'vue-meta'
import addGetWeekToDate from "@/services/utils/getWeek";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import axios from "@/services/api/axios"
// import isOnline from "@/services/api/network";




Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import VueFeather from 'vue-feather';

import VueApexCharts from 'vue-apexcharts'
import './assets/tailwind.css'
Vue.use(VueApexCharts)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash });

setInteractionMode('lazy')
//this is set up globally so as to only validate input field when submit button is clicked
// you can pass mode="passive" to your ValidationProvider wrapper component or dont
// from the documentation at the time of refactor, it can only take one mode per time




Vue.config.productionTip = false
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('vue-feather', VueFeather);
Vue.component('apexchart', VueApexCharts)

Vue.prototype.$http = axios;
axios.defaults.timeout = 20000;


Vue.prototype.$displaySnackbar = displaySnackbar
Vue.prototype.$displayNetworkAlert = displayNetworkAlert
Vue.prototype.$siteName = getSiteName
Vue.prototype.$FRONT_END_AUTH_URL = process.env.VUE_APP_FRONTEND_AUTH_URL

addGetWeekToDate()

axios.interceptors.response.use(
    response => {
      // if(isOnline()) {
      if (response.status === 200 || response.status === 201 || response.status === 206) {
        return Promise.resolve(response);
      } else {
        console.log({response})
        return Promise.reject(response);
      }
      // }
      // else {
      //   displayNetworkAlert(true)
      // }
    },
    error => {
      // if(isOnline() &&  !error) {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            console.log(error.response.data.password)
            console.log(error.response.data)
            if(error.response.data.password){
              Vue.prototype.$displaySnackbar({
                message: error.response.data.password.toString(),
                success: false
              })
            }
            else if(error.response.data.email){
              Vue.prototype.$displaySnackbar({
                message: error.response.data.email.toString(),
                success: false
              })
            }
            else {
              displaySnackbar({
                message: error.response.data.detail,
                success: false
              })
            }
            break;

          case 401:
            // eslint-disable-next-line no-case-declarations
            let errorKey = Object.keys(error.response.data)
            Vue.prototype.$displaySnackbar({
              message: error.response.data[errorKey[0]],
              success: false
            })

            break;
          case 403:
            Vue.prototype.$displaySnackbar({
              message: 'You don\'t have permission to access this',
              success: false
            })
            // router.replace({
            //   path: "/login",
            //   query: { redirect: router.currentRoute.fullPath }
            // });
            break;
          case 404:
            displaySnackbar({
              message:error.response.data.detail,
              success: false
            });
            break;
          case (500):
            setTimeout(() => {
              router.replace({
                path: "/errorPage?error=500",
                query: {
                  redirect: router.currentRoute.fullPath
                }
              });
            }, 1000);
            break;
          case (503):
            setTimeout(() => {
              router.replace({
                path: "/errorPage?error=500",
                query: {
                  redirect: router.currentRoute.fullPath
                }
              });
            }, 1000);

        }
        return Promise.reject(error.response);
      }
      // }
      // else {
      //   displayNetworkAlert(true)
      //
      // }
    }
);

new Vue({
  router,
  store,
  vuetify,
  VuePhoneNumberInput,
  render: h => h(App)
}).$mount('#app')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
