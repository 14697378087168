<template>
  <div>
    <div :class="['lg:tw-flex lg:tw-justify-between align-center', item.isReverse?'tw-flex-row-reverse':'']">
      <!-- column 1 -->
      <div class="lg:tw-w-5/12">
        <div class="lg:tw-mx-16 tw-mx-7 lg:tw-w-3/5">
          <small>{{ item.stage }}</small>
          <h1>{{ item.title }}</h1>
          <div class="tw-mt-5 descWidth">
            <slot name="description"></slot>
          </div>
          <div class="tw-flex align-center tw-my-5">
            <BaseButton text button-text="GET STARTED" @click="openDialog()"></BaseButton>
            <vue-feather class="ml-2" style="color: #008EEF; width: 18px" type="arrow-right"/>
          </div>
        </div>
      </div>

      <!-- column 2 -->
      <div class="lg:tw-w-7/12">
        <div :class="[$vuetify.breakpoint.mobile? 'tw-mx-7':'']">
          <v-img
              contain
              :src="item.image"
          />
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";

export default {
  name: "StageOne",
  components: {BaseButton},
  props: {
    item: Object
  },
  methods: {
    openDialog() {
      console.log('we dey')
      this.$emit('dialog')
    }
  }

}
</script>

<style scoped lang="scss">
small {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 120%;
  display: flex;
  letter-spacing: -0.03em;
  color: #D0DCE4;
  @media (max-width: 700px) {
    font-size: 40px;
  }
}
.descWidth{
  max-width: 414px;
}

h1 {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  letter-spacing: -0.03em;
  color: #1E323F;

  @media (max-width: 700px) {
    font-size: 24px;
  }

}

</style>