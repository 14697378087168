<template>
<div>
  <svg width="68" height="66" viewBox="0 0 68 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M12 25C12 13.402 21.402 4 33 4H35C46.598 4 56 13.402 56 25C56 36.598 46.598 46 35 46H33C21.402 46 12 36.598 12 25Z" fill="#001343"/>
      <path d="M41.5 27.5C41.5 27.942 41.3244 28.366 41.0118 28.6785C40.6993 28.9911 40.2754 29.1667 39.8333 29.1667H29.8333L26.5 32.5V19.1667C26.5 18.7246 26.6756 18.3007 26.9882 17.9882C27.3007 17.6756 27.7246 17.5 28.1667 17.5H39.8333C40.2754 17.5 40.6993 17.6756 41.0118 17.9882C41.3244 18.3007 41.5 18.7246 41.5 19.1667V27.5Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d" x="-4" y="-4" width="76" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="6"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.904167 0 0 0 0 0.904167 0 0 0 0 0.904167 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

</div>
</template>

<script>
export default {
  name: "FeedbackIcon"
}
</script>

<style scoped>

</style>