import {getOffer, getUserProfile, makeOffer, rejectOffer,getPayment,getAllInteractionComment, deleteComment} from "@/services/api/APIService";

export default {

    getUserProfile({commit}, id) {
        return getUserProfile(id).then(
            res => {
                commit('setUserProfile', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },

    makeOffer({commit}, data) {
        return makeOffer(data).then(
            res => {
                commit('setMakeOffer', res.data)
                return Promise.resolve()
            },
            error => { return Promise.reject(error) }
        )
    },

    rejectOffer({commit}) {
        return rejectOffer().then(
            res => {
                commit('setRejectOffer', res.data)
                return Promise.resolve()
            },
            error => {return Promise.reject(error)}
        )
    },

    getOffer({commit}, data) {
        return getOffer(data).then(
            res => {
                commit('set_get_Offer_State', res.data)
                return Promise.resolve()
            },
            error => { return Promise.reject(error)}
        )
    },

    async get_InteractionComment({commit}, data){
        await getAllInteractionComment(data).then(
            res => {
                commit('set_allInteractionComment', res.data)
            }).catch(err=>{
                console.log(err)
        })
    },
    deleteSingleComment({commit}, data){
        deleteComment(data).then(
            res=>{
                commit('removeComment', data.id)
                console.log(res)
            }
        ).catch(err=>{
            console.log(err)
        })
    },

    getPaymentDetails({commit}, payload) {
       return getPayment(payload)
            .then(res=>{
                commit('setPaymentDetails', res.data)
            })
            .catch(err=>{
                console.log(err)
            })
    },
    // async getActivityLog({commit}, payload){
    //     console.log(payload)
    //     await getApplicationActivityLog(payload)
    //         .then(res=>{
    //             console.log(res.data,'heererrererer')
    //             commit('set_activity_log', res)
    //             console.log(res)
    //
    //         })
    //         .catch(err=>{
    //             console.log(err)
    //         })
    // }

}