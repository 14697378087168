<template>
  <div class="tw-flex">
    <svg @click="goToDashboard" class="tw-mr-2" width="99" height="30" viewBox="0 0 99 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <circle cx="13.572" cy="11.2619" r="7.40534" stroke="#008EEF" stroke-width="5.6126"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6987 23.3505C22.8627 22.3524 21.3832 22.2423 20.2543 22.891C18.0611 24.1511 15.5407 24.7737 12.9817 24.6608C10.4227 24.548 7.96687 23.7061 5.89301 22.2578C4.82559 21.5124 3.34219 21.4918 2.42157 22.4124C1.50095 23.333 1.49295 24.84 2.52518 25.6334C5.44509 27.8779 8.99628 29.1915 12.7112 29.3681C12.7321 29.3691 12.7531 29.3701 12.774 29.371C12.795 29.372 12.8159 29.3728 12.8369 29.3737C16.5529 29.5246 20.2059 28.5287 23.312 26.5499C24.4101 25.8503 24.5347 24.3485 23.6987 23.3505Z" fill="#008EEF"/>
      </g>
      <path d="M38.9873 22.679C37.5595 22.679 36.3304 22.3898 35.3002 21.8114C34.276 21.227 33.4868 20.4016 32.9325 19.3352C32.3782 18.2628 32.1011 16.9946 32.1011 15.5306C32.1011 14.1028 32.3782 12.8497 32.9325 11.7712C33.4868 10.6928 34.267 9.85237 35.2731 9.2499C36.2852 8.64743 37.4721 8.34619 38.8337 8.34619C39.7494 8.34619 40.6019 8.4938 41.3912 8.78901C42.1864 9.07819 42.8793 9.51498 43.4697 10.0994C44.0661 10.6838 44.53 11.4188 44.8614 12.3044C45.1927 13.184 45.3584 14.2143 45.3584 15.3951V16.4524H33.6374V14.0666H41.7346C41.7346 13.5124 41.6141 13.0214 41.3731 12.5936C41.1321 12.1659 40.7977 11.8315 40.37 11.5905C39.9482 11.3435 39.4572 11.22 38.8969 11.22C38.3125 11.22 37.7944 11.3555 37.3426 11.6266C36.8967 11.8917 36.5473 12.2502 36.2943 12.7021C36.0412 13.1479 35.9117 13.6449 35.9057 14.1932V16.4615C35.9057 17.1483 36.0322 17.7417 36.2852 18.2418C36.5443 18.7418 36.9088 19.1274 37.3787 19.3985C37.8486 19.6696 38.4059 19.8052 39.0506 19.8052C39.4783 19.8052 39.8699 19.7449 40.2254 19.6244C40.5808 19.5039 40.8851 19.3232 41.1381 19.0822C41.5971 18.6451 42.1485 18.2251 42.781 18.2668L43.108 18.2884C44.2527 18.3639 45.0834 19.4825 44.3907 20.3968C44.3194 20.4909 44.2443 20.5829 44.1655 20.6727C43.6113 21.3053 42.8943 21.7993 42.0147 22.1548C41.1411 22.5042 40.132 22.679 38.9873 22.679Z" fill="#008EEF"/>
      <path d="M51.2115 14.3829V20.483C51.2115 21.546 50.3497 22.4078 49.2867 22.4078C48.2236 22.4078 47.3618 21.546 47.3618 20.483V10.3615C47.3618 9.34827 48.1831 8.52693 49.1963 8.52693C50.2095 8.52693 51.0308 9.34828 51.0308 10.3615V10.976H51.1935C51.5007 10.1687 52.0158 9.53004 52.7388 9.06012C53.4618 8.58417 54.3384 8.34619 55.3686 8.34619C56.3325 8.34619 57.173 8.55706 57.8899 8.97879C58.6069 9.40051 59.1642 10.003 59.5618 10.7862C59.9594 11.5634 60.1582 12.4912 60.1582 13.5696V20.483C60.1582 21.546 59.2964 22.4078 58.2333 22.4078C57.1702 22.4078 56.3084 21.546 56.3084 20.483V14.2564C56.3145 13.4069 56.0976 12.7442 55.6578 12.2683C55.218 11.7863 54.6125 11.5453 53.8413 11.5453C53.3232 11.5453 52.8653 11.6568 52.4677 11.8797C52.0761 12.1026 51.7688 12.4279 51.5459 12.8557C51.329 13.2774 51.2176 13.7865 51.2115 14.3829Z" fill="#008EEF"/>
      <path d="M71.5849 16.4976V10.4518C71.5849 9.38874 72.4467 8.52693 73.5098 8.52693C74.5729 8.52693 75.4347 9.38874 75.4347 10.4518V20.5598C75.4347 21.5804 74.6073 22.4078 73.5866 22.4078C72.566 22.4078 71.7386 21.5804 71.7386 20.5598V19.8865H71.594C71.2807 20.6998 70.7596 21.3535 70.0306 21.8475C69.3076 22.3416 68.425 22.5886 67.3827 22.5886C66.4549 22.5886 65.6386 22.3777 64.9337 21.956C64.2288 21.5343 63.6775 20.9348 63.2799 20.1576C62.8883 19.3804 62.6895 18.4496 62.6834 17.3652V10.4518C62.6834 9.38874 63.5452 8.52693 64.6083 8.52693C65.6714 8.52693 66.5332 9.38874 66.5332 10.4518V16.6784C66.5393 17.4977 66.7592 18.1454 67.1929 18.6213C67.6267 19.0973 68.2081 19.3352 68.9371 19.3352C69.401 19.3352 69.8348 19.2298 70.2384 19.0189C70.6421 18.8021 70.9674 18.4827 71.2144 18.061C71.4675 17.6393 71.591 17.1182 71.5849 16.4976Z" fill="#008EEF"/>
      <path d="M79.93 22.4078C78.8669 22.4078 78.0051 21.546 78.0051 20.483V10.3615C78.0051 9.34827 78.8265 8.52693 79.8397 8.52693C80.8528 8.52693 81.6742 9.34828 81.6742 10.3615V10.976H81.8368C82.126 10.1626 82.608 9.52101 83.2828 9.05108C83.9575 8.58115 84.7648 8.34619 85.7047 8.34619C86.6566 8.34619 87.4669 8.58417 88.1357 9.06012C88.8044 9.53004 89.2502 10.1687 89.4732 10.976H89.6177C89.9009 10.1807 90.413 9.54511 91.154 9.06915C91.9011 8.58718 92.7837 8.34619 93.8019 8.34619C95.0972 8.34619 96.1485 8.75888 96.9558 9.58427C97.7692 10.4036 98.1758 11.5664 98.1758 13.0726V20.4875C98.1758 21.5481 97.3161 22.4078 96.2555 22.4078C95.1949 22.4078 94.3351 21.5481 94.3351 20.4875V13.8317C94.3351 13.0605 94.1302 12.4821 93.7206 12.0966C93.3109 11.711 92.7988 11.5182 92.1843 11.5182C91.4854 11.5182 90.9402 11.7411 90.5486 12.1869C90.157 12.6267 89.9612 13.2081 89.9612 13.9311V20.5417C89.9612 21.5723 89.1256 22.4078 88.095 22.4078C87.0644 22.4078 86.2289 21.5723 86.2289 20.5417V13.7503C86.2289 13.0696 86.0331 12.5273 85.6414 12.1237C85.2559 11.72 84.7468 11.5182 84.1142 11.5182C83.6864 11.5182 83.3008 11.6266 82.9574 11.8435C82.6201 12.0544 82.352 12.3526 82.1531 12.7382C81.9543 13.1178 81.8549 13.5636 81.8549 14.0757V20.4829C81.8549 21.546 80.9931 22.4078 79.93 22.4078Z" fill="#008EEF"/>
      <defs>
        <clipPath id="clip0">
          <rect width="24.8759" height="29.0218" fill="white" transform="translate(0.763184 0.779541)"/>
        </clipPath>
      </defs>
    </svg>

<!--    <svg width="48" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <rect x="0.61" y="0.61" width="46.78" height="18.78" rx="8.27889" fill="white" stroke="#AAB7DB" stroke-width="1.22"/>-->
<!--      <path d="M11.5661 14.4443V6.14798H14.8964C15.7577 6.14798 16.4135 6.34946 16.8638 6.75242C17.3221 7.14748 17.5512 7.65711 17.5512 8.28131C17.5512 8.80279 17.409 9.22156 17.1246 9.53761C16.848 9.84576 16.5083 10.0551 16.1053 10.1658C16.5794 10.2606 16.9705 10.4976 17.2786 10.8769C17.5868 11.2482 17.7409 11.6828 17.7409 12.1806C17.7409 12.8364 17.5038 13.3776 17.0298 13.8043C16.5557 14.2309 15.8841 14.4443 15.0149 14.4443H11.5661ZM13.0831 9.63242H14.6712C15.0979 9.63242 15.4258 9.53366 15.6549 9.33613C15.8841 9.1386 15.9986 8.8581 15.9986 8.49465C15.9986 8.14699 15.8841 7.8744 15.6549 7.67687C15.4337 7.47144 15.0979 7.36872 14.6475 7.36872H13.0831V9.63242ZM13.0831 13.2117H14.7779C15.2283 13.2117 15.5759 13.109 15.8209 12.9035C16.0737 12.6902 16.2001 12.3939 16.2001 12.0146C16.2001 11.6275 16.0698 11.3233 15.809 11.1021C15.5483 10.8808 15.1967 10.7702 14.7542 10.7702H13.0831V13.2117ZM19.0429 14.4443V6.14798H24.4592V7.36872H20.5599V9.64427H24.1036V10.8295H20.5599V13.2235H24.4592V14.4443H19.0429ZM27.8266 14.4443V7.36872H25.4088V6.14798H31.7733V7.36872H29.3436V14.4443H27.8266ZM31.4841 14.4443L34.5182 6.14798H36.2249L39.2589 14.4443H37.6471L36.9834 12.5243H33.7478L33.0723 14.4443H31.4841ZM34.1626 11.3391H36.5686L35.3597 7.8902L34.1626 11.3391Z" fill="#142E70"/>-->
<!--    </svg>-->

  </div>
</template>

<script>
export default {
  name: "EnumWithWordIcon",
  methods:{
    goToDashboard(){
      if(this.$route.query.from){
        console.log('hi')
        let token = sessionStorage.getItem('userToken')
        console.log(token)
        window.location.href =
            `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}&to=admin-dashboard`

      }
      else if(this.$router.currentRoute.name !== 'LandingPage' && !this.$route.query.from){
        this.$router.push('/dashboard')
      }
    }
  }
}
</script>

<style scoped>

</style>
