<template>
  <div>
    <div class="mb-20">
      <Header>
        <template #right-header-component>
          <div class="tw-flex">
            <v-btn class="md:tw-mr-3 tw-rounded-lg" color="#008EEF" outlined :href="loginRoute" x-large>
              <span class=" text-capitalize">Login</span>
            </v-btn>
            <div class="lg:tw-block tw-hidden">
              <v-btn block class="tw-rounded-lg" color="#008eef" x-large :href="registerRoute">
                <span class="btn-text text-capitalize">Sign Up</span>
              </v-btn>
            </div>
          </div>
        </template>
      </Header>
    </div>
    <LandingPageHeader @dialog="openModal"/>
    <LandingPageOptimize/>
    <how-it-works-view @dialog="openModal"/>
    <LandingPageFooter @dialog="openModal"/>
    <WaitingListForm :dialog="dialog" @close="closeModal"></WaitingListForm>
  </div>
</template>

<script>
import HowItWorksView from "@/views/HowItWorksView";
import LandingPageFooter from "@/components/onboarding/components/LandingPageFooter";
import LandingPageHeader from "@/components/onboarding/components/LandingPageHeader";
import Header from "@/components/onboarding/reuseables/Header";
import LandingPageOptimize from "@/components/onboarding/components/LandingPageOptimize";
import WaitingListForm from "@/components/onboarding/components/WaitingListForm";

export default {
  name: "LandingPage",
  data() {
    return {
      dialog: false,
      loginRoute: `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=recruiter`,
      registerRoute: `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/signup?site=recruiter`
    }
  },
  methods: {
    openModal() {
      this.$router.push({
        name: 'RegistrationPage',
        params: {type: 'business'}
      })
    },
    closeModal() {
      this.dialog = false
    }
  },
  components: {
    WaitingListForm, LandingPageOptimize, Header, LandingPageHeader, LandingPageFooter, HowItWorksView
  },
  beforeCreate() {
    const { $route: { query: { token } } } = this
    if (token) {
      this.$router.push({
        name: 'Login',
        query: {
          token
        }
      })
    }
    else {
      window.location.href = process.env.VUE_APP_FRONTEND_AUTH_URL+'/recruit'

    }
  }
}
</script>

<style scoped>
.btn-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: white;
}
</style>
