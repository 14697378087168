import {
    getCriteria, getJobInfos,
    getOtherCriteria,
    sendCriteria, sendOtherCriteria,
    updateCriteria,
    updateOtherCriteria,
    deleteCriterias
} from "../../services/api/APIService";

export default {
    createCriteria({commit}, payload) {
        return sendCriteria(payload).then(
            response => {
                commit("SET_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    updateCriterias({commit}, data) {
        return updateCriteria(data.info, data.id).then(
            response => {
                commit("SET_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getCriterias({commit}, data) {
        return getCriteria(data).then(
            response => {
                commit("SET_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getJobDetails({commit}, id) {
        return getJobInfos(id).then(
            response => {
                commit("SET_JOB", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createOtherCriterias({commit}, payload) {
        return sendOtherCriteria(payload).then(
            response => {
                commit("SET_OTHER_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    updateOtherCriterias({commit}, data) {
        return updateOtherCriteria(data).then(
            response => {
                commit("SET_OTHER_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getOtherCriteria({commit}, data) {
        return getOtherCriteria(data).then(
            response => {
                commit("SET_OTHER_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    deleteCriteria({commit}, data) {
        return deleteCriterias(data).then(
            response => {
                commit("SET_OTHER_CRITERIA", response.data);
                return Promise.resolve();
            },
            error => {
                return Promise.reject(error);
            }
        );
    }
};