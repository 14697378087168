import {fetchCriteriaTopics, getExternalSkills} from "@/services/api/APIService";

export default {
    async fetchCriteriaTopics({commit}, {criteria_name, criteria_id}) {
        commit("setFetchingCriteriaTopics", true)
        try {
            const {data} = await fetchCriteriaTopics(criteria_id)
            commit("setCriteriaQuestionsInView", {
                title: criteria_name,
                items: data
                    .map(({title, id, number_of_questions, topic_time}) => ({
                        id,
                        title,
                        number_of_questions,
                        duration: topic_time
                    }))
            })
        } finally {
            commit("setFetchingCriteriaTopics", false)
        }
    },

    async getExternalSkills({commit}, {site_name, id}){
        return getExternalSkills(site_name, id).then(
            res => {
                commit('setSkills', res.data);
                return Promise.resolve(res.data);
            }
        )
    }
}