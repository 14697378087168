export default {
    getEditCompanyProfile: state => state.editProfile,
    getCompanyProfile: state => state.companyProfile,
    getReviews: state => state.reviews,
    getReviewInsight: state => state.ratingInsight,
    getCompanyAdmin: state => state.companyAdmin,
    isLoading: state => state.loading,
    companyDescriptionAndMedia: state => state.descriptionAndMedia,
    companyFaqs: state => state.faqs,
    companyAwards: state => state.awards

}