<template>
<div>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      top
      left
      transition="slide-y-reverse-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div  v-bind="attrs" v-on="on" class="tw-flex">
        <small class="tw-mt-3.5" v-if="$route.meta.dashboard">Hello! Experiencing a problem?</small>
        <feedback-icon/>
      </div>
    </template>
    <div class="card md:tw-w-96 ">
      <div class="d-flex header align-center white--text px-5 justify-space-between">
        <h3 class="tw-uppercase">Enum Support team</h3>
        <Minimize2Icon @click="menu = false" size="18" />
      </div>

      <div class="pb-5 scroll">

        <div class="ma-5 ">
          <div class=" enum-card pa-3 ">
            <h3>Hello! Experiencing a problem?<br><br>
              Please send a message or call us and we will get on it immediately.
              Hotline: +2349060008609</h3>
          </div>
        </div>
<!--FEEDBACK-->
        <v-slide-y-reverse-transition name="fade">
        <div  v-if="company_feedback !== ''" class="feedBackScrollPosition">
          <div v-for="(feedBack, index) in sessionStoredComment" :key="index" class="ma-5">
            <div class=" enum-feedback-card pa-3 ">
              <h3 class="text-capitalize">{{feedBack.comment}}</h3>
              <div class="tw-flex align-center">
                <v-img
                    v-if="feedBack.image"
                    :src="feedBack.image"
                    class="round-image pr-2"
                    transition="fade"
                    height="200"
                    width="323"
                    contain

                />
              </div>
            </div>
            <div class="d-flex mt-1 align-center justify-end">
              <h3 class="tw-text-enumGrey3 text-right text-capitalize enum-team-text">{{ name }}</h3>
              <v-avatar class="mx-1" size="5" color="#9CABB5"></v-avatar>
              <h3 class="tw-text-enumGrey3 text-right enum-team-text">{{ processTime(feedBack.created) }}</h3>
            </div>
            <!--        RESPONSE-->
            <v-slide-y-reverse-transition name="fade-enter">
              <div v-if="feedBackSuccess" class="mt-5" >
                <div class=" enum-card pa-3 ">
                  <h3>Thank you! Your feedback has been received.</h3>
                </div>
              </div>
            </v-slide-y-reverse-transition>
          </div>
        </div>
          <v-slide-y-reverse-transition name="fade-enter">

            <div class="tw-flex align-center">
              <v-img
                  v-if="imageFile.image_url"
                  :src="imageFile.image_url"
                  class="round-image pr-2"
                  transition="fade"
                  height="200"
                  width="323"
                  contain

              />
            </div>
          </v-slide-y-reverse-transition>
        </v-slide-y-reverse-transition>
      </div>

      <div class="footer d-flex px-5 py-3">
        <v-text-field id="comment" hide-details solo v-model="comment" @keydown.enter.prevent='sendFeedBack()'  label="Tell us the problem">

          <template v-slot:append>
            <FeedBackImageUpload @sendImage="getImage"/>
          </template>


        </v-text-field>
        <v-btn id="sendFeedBack" @click="sendFeedBack()" color="transparent" class="pa-0 ma-0 mt-1" depressed :loading="loading">
          <v-avatar class="ml-2" color="#008EEF">
          <div >
           <SendIcon class="icon ml-n1 mt-n1"></SendIcon>
          </div>
        </v-avatar>
        </v-btn>
      </div>


    </div>

  </v-menu>


</div>
</template>

<script>
import {Minimize2Icon, SendIcon} from "vue-feather-icons"
import FeedbackIcon from "@/components/UIComponents/reusablesIcon/FeedbackIcon";
import {mapGetters} from "vuex";
import FeedBackImageUpload from "@/components/feedBack/FeedBackImageUpload";
import {viewLesson} from "@/services/storage/aws";
// import {userGetFeedBack} from "@/services/api/APIService";
export default {
name: "FeedBack",
  components: {FeedBackImageUpload, SendIcon, FeedbackIcon, Minimize2Icon},
  data(){
  return{
    imageFile:{},
    image:'',
    comment:"",
    menu: false,
    company_feedback: "",
    company_name: "",
    loading: false,
    feedBackSuccess: false,
    sessionStoredComment :"",
    storedComment: "",
    getFeedback:{}
  }
  },
  watch: {
    sessionStoredComment: {
      async handler(val) {
        if (val) {
          for (const comment of val) {
            comment.image = await viewLesson({url:comment.image})
          }
        }
      },
      immediate: true
    }
  },
  created() {
    sessionStorage.setItem("feedBack", JSON.stringify([]));
    // let data = JSON.parse(sessionStorage.getItem('userData'))
    // userGetFeedBack(data).then(res=>{
    //   this.getFeedback = res
    // }).catch(e=>console.log(e))
  },

  computed:{
  ...mapGetters("feedBack",["feedback"]),
  ...mapGetters('editCompany', ["getCompanyProfile"]),
    ...mapGetters("onBoarding",["name"])

  },
  methods: {
    async getImage(file){
      this.imageFile= file.imageInfo
      this.imageFile.image_url = await viewLesson({ url: this.imageFile.image_url })
      this.image = file.feedback_image_url
      console.log(this.image, 'here the imae')
    },
    sendFeedBack() {
      if(this.comment || this.image) {
        this.loading = true
        // this.feedBackSuccess = false

        let user = JSON.parse(sessionStorage.getItem("userData"))
        let data =
            {
              user: user.email,
              // feedback_comment: this.comment,
              logo:this.getCompanyProfile.company_logo,
              from_app:"recruiter",
              name:user.name,
              tag:"unassigned",
              role:'user',
              job_role_or_company_name:this.getCompanyProfile.company_name,
              email:user.email,
              comment:this.comment,
              image:this.image
            }
        this.$store.dispatch("feedBack/giveFeedBack", data).then(
            res => {
              this.loading = false
              this.comment = ""
              this.storedComment = res
              this.company_feedback = this.feedback
              setTimeout(() => {
                this.feedBackSuccess = true
              }, 1000);
              let feedBack = JSON.parse(sessionStorage.getItem("feedBack"));

              feedBack.push(this.storedComment)
              sessionStorage.setItem("feedBack", JSON.stringify(feedBack));
              let updatedDetails = JSON.parse(sessionStorage.getItem("feedBack"));
              this.sessionStoredComment = updatedDetails
            }
        ).catch(err => {
          this.loading = false
          console.log(err)
        })
      }
    },
    processTime(createdTime) {
      if (!createdTime) return;
      let dateAndTimeNow =JSON.stringify( new Date());

      let gottenTimeStamp = createdTime.split("T")[1]
      let gottenTime = gottenTimeStamp.split(".")[0];

      let timeNow = dateAndTimeNow.split("T")[1]
      let exactTime = timeNow.split(".")[0];
      let gottenHour = parseInt(gottenTime.split(":")[0])
      let gottenMins = parseInt(gottenTime.split(":")[1])

      let exactHour = parseInt(exactTime.split(":")[0]) + 1
      let exactMins = parseInt(exactTime.split(":")[1])

      let newHour = exactHour - gottenHour
      let newMins = exactMins - gottenMins

      if(newHour === 0 && newMins === 0 ){
        return "Now"
      }
       if(newHour === 0 && newMins > 0 ){
        return newMins+ " min" + (newMins > 1 ? "s" : "") +" ago"
      }
       if(newHour > 0 && newHour < 23 ){
        return newHour+ " hour" + (newHour > 1 ? "s" : "") +" ago"
      }
       if(newHour > 23 ){
         let day = Math.floor(newHour / 24);
        return day+ " day" + (day > 1 ? "s" : "") +" ago"
      }

    }
  }
}
</script>

<style scoped>
.card{
  background-color: #FFFFFF;
  border-radius: 10px;
}
.v-menu__content{
  box-shadow: 0 8px 12px #E7E7E7;
  margin-top: -10px;
}
h3{
  font-family: DM Sans;
  font-style: normal;
}
.header{
  height: 60px;
  background: #001343;
  font-weight: 500;
}
.enum-card{
  background: #F2F2F2;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #031849;
}
.enum-feedback-card{
  background: #EEF8FF;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #031849;
}
.enum-team-text{
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
.footer{
  border-top: 1px solid #D0DCE4;
}
.icon{
  color: #FFFFFF;
  transform: rotate(45deg);
}
.scroll {
  max-height: 225px;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;
  }
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
  bottom: 0;
}
.scroll ::v-deep .feedBackScrollPosition:last-child {
  scroll-snap-align: end;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}
</style>
