export default {
    getAllAdminJobSuccess: (state, data) => {
        state.allAdminJobs = data;
    },
    getAllDraftJobSuccess: (state, data) => {
        state.allDraftJobs = data;
    },
    getAllClosedJobSuccess: (state, data) => {
        state.allClosedJobs = data;
    },
    getAllLiveJobSuccess: (state, data) => {
        state.allLiveJobs = data;
    },
    getJobDetailsSuccess: (state, data) => {
        state.jobDetail = data;
    },
    getLiveCandidatesSuccess: (state, data) => {
        state.listCandidates = data;
    },
    removeJob: (state, data) => (
        state.allAdminJobs = state.allAdminJobs.filter(element => data !== element.id)
    )

};