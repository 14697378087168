export const removeUnwantedProperties = (model, properties) => {
    let objectKeys = Object.keys(model)
    properties.map(property => {
        objectKeys = objectKeys.filter(key => key !== property)
    })
    let newModel = {}
    objectKeys.map(key => {
        newModel[key] = model[key]
    })
    return newModel
}

// this method should be called this way 'this.$siteName' in the component that require site name
export default function getSiteName (){
    let user = JSON.parse(sessionStorage.getItem('userData'))
    if(user){
        return user.site_name || null

    }
}
