export default {
    setCriteriaQuestionsInView: (state, questions_overview) => state.criteriaTopicsInView = questions_overview,
    setShowQuestionCards: (state, open) => state.showQuestionCards = open,
    setShowSampleQuestions: (state, open) => state.showSampleQuestions = open,
    toggleExpand: (state, {id, expand}) => {
        state.sampleQuestions.forEach(question => question.expand = false)
        const foundQuestion = state.sampleQuestions.find(question => question.id === id)
        foundQuestion.expand = expand
    },
    setFetchingCriteriaTopics: (state, fetching) => state.fetchingCriteriaTopics = fetching,
    setSampleQuestions: (state, questions) => state.sampleQuestions = questions,
    setSkills:(state,data)=> {
        state.externalSkills = data
    }
}