import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const initialState = {
    editProfile: {},
    companyProfile: {},
    ratingInsight:{},
    reviews: [
    ],
    companyAdmin: [],
    descriptionAndMedia:[],
    loading: false,
    faqs:[],
    awards:[],
}
export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};