import axios from "@/services/api/axios"
import authHeader from "@/services/api/auth-header";
import METHODS from "@/services/api/apiMethods";
import Vue from 'vue'
import isOnline from "@/services/api/network";

let BASE_URL;

if (process.env.NODE_ENV === "development") {
    BASE_URL = process.env.VUE_APP_DEV_BASE_URL;
} else {
    BASE_URL = process.env.VUE_APP_PROD_BASE_URL;
}

const callEndpoint = async ({method, url, data, needsAuth = true}, SERVICE_BASE_URL = BASE_URL) => {
    const {GET, DELETE} = METHODS
    url = SERVICE_BASE_URL + url
    if (!method) method = GET
    if (await isOnline()) {

        if (method === GET || method === DELETE) {
            if (needsAuth) {
                return axios[method](url, {headers: authHeader()})
            } else return axios[method](url)
        } else {
            if (needsAuth) {
                return axios[method](url, data, {headers: authHeader()})
            } else return axios[method](url, data)
        }
    }
    else {
        Vue.prototype.$displayNetworkAlert(true)
    }
}


export default callEndpoint
