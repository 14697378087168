import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state={
    interviewScheduleDetails:{
        job:2,
        user_invited:['gb.emikp@gmail.com'],
    },
    isApplicantInvited:false,
    isLoading:false,
    allInterviewComment:[],
    interviewGrade:{},
    interviewStatus:{},
    comment_loading:false,
    allInterviewQuestion:[],
    allProgramInterviewComment:[],
    interviewId:'',
    questionId:'',
    recommendationComment:[]


}
export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}
