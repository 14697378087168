export default {
    SET_INTERVIEW_SCHEDULE_DETAILS:(state,data)=>
        ({...state.interviewScheduleDetails} = data),

    SET_INTERVIEW_STATUS:(state,status)=>{
        state.interviewStatus= status
    },
    SET_INTERVIEW_COMMENT:(state,comments)=>{
        state.allInterviewComment = comments
    },
    SET_INTERVIEW_GRADE:(state,grade)=>{
        state.interviewGrade = grade
    },
    setInterviewQuestion(state, data){
        state.allInterviewQuestion = data
    },
    setProgramInterviewComment(state,comment){
        console.log(comment)
        state.allProgramInterviewComment = comment

    },
    setLoader(state, status){
        state.isLoading = status
    },
    setCommentLoader(state, status){
        state.comment_loading = status
    },
    setInterviewId(state, id){
        state.interviewId = id
    },
    setQuestionId(state, id){
        state.questionId = id
    },
    setRecommendationComment(state, data){
        state.recommendationComment = data
    }

}
