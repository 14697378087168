import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const initialState = {
    weeklyData: [],
    monthlyData: []
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};