export default function authHeader() {
    let token = sessionStorage.getItem('userToken')

    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }

}
