import {giveFeedBack} from "@/services/api/APIService";

export default {
    giveFeedBack({commit}, data){
        return giveFeedBack(data).then(
            res => {
                console.log(res)
                commit("giveFeedBackSuccess", res.data);
                return Promise.resolve(res.data);
            }
        )
    }
}