// import {getAllCandidatesInATalentPool} from "@/services/api/APIService";

export default {
    updateTalentPool: (state, data) => {
        state.all_talent_pool.unshift(data)
        console.log(state.all_talent_pool)
    },
    getTalentPoolID: (state, data) => {
        state.talent_pool_id = data;
    },
    allCandidatesInATalentPool:(state, data) => {
        state.all_candidates_in_a_talent_pool = data;
    },
    getAllTalentPool:(state, data) => {
        state.all_talent_pool = data;
    },
    moveCandidateToATalentPoolSuccessful: (state, data) => {
        console.log(data)
        state.all_candidates_in_a_talent_pool = state.all_candidates_in_a_talent_pool.filter(item=> item.candidate_email !== data)
        console.log(state.all_candidates_in_a_talent_pool)
    }
}