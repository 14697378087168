import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations";


// const user = sessionStorage.getItem("userData") ? JSON.parse(sessionStorage.getItem("userData")) : null
// console.log(user)
// const userToken = sessionStorage.getItem("userToken") ? JSON.parse(sessionStorage.getItem("userToken")) : null

const initialState =
    // userToken
    // ? { status: { loggedIn: true }, user, isPresent: false }
    {
        status: { loggedIn: false },
        user: {
            gender: "",
            email: "",
            first_name: "",
            last_name: "",
            image: "",
            date_of_birth: "",
            industry: "",
            id: "",
            jti: "",
            name: "",
            organisation: "",
            token_type: "",
            user_id: ""
        },
        isPresent: false
    };

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
}
