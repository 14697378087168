import {
    getInterviewComment,
    getInterviewStatus, getProgramInterviewComment,
    getProgramInterviewQuestion,
    submitInterviewGrade,getRecommendation
} from "@/services/api/APIService";

export default {
    getInterviewComment({commit},id){
        getInterviewComment(id)
            .then(res=>{
                console.log(res)
                commit('SET_INTERVIEW_COMMENT',res.data)

            })
            .catch(err=>{
                console.log(err)
            })
    },
    submitInterviewGrade(context,data){
        submitInterviewGrade(data)
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(err)
            })

    },
    getInterviewStatus({commit},data){
        getInterviewStatus(data.email,data.id)
            .then(res=>{
                commit('SET_INTERVIEW_STATUS',res.data[0])
            })
            .catch(err=>{
                console.log(err)
            })
    },
    async getInterviewQuestion({commit}, payload) {
        commit('setLoader', true)
        getProgramInterviewQuestion(payload)
            .then(res=>{
                commit('setInterviewQuestion', res.data)
                commit('setLoader', false)


            })
            .catch(err=>{
            console.log(err)
                commit('setLoader', false)
            }
    )
    },
    async getProgramComment({commit}, payload) {
        console.log(payload)
        commit('setCommentLoader', true)
        getProgramInterviewComment(payload).then(res=>{
                commit('setProgramInterviewComment', res.data.comments)
            }).catch(err=>{
                    console.log(err)
                }
            ).finally(()=>commit('setCommentLoader',false))
    },

    async getProgramRecommendation({commit}, payload){
        commit('setCommentLoader', true)
        getRecommendation(payload).then(res=>{
            commit('setRecommendationComment', res.data)
        }).catch(err=>{
            console.log(err)
        }).finally(()=>commit('setCommentLoader', false))
    }



}
