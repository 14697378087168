<template>
  <v-app>
    <SnackBar
        :success="success"
        :show-snack-bar="showSnackBar"
        :snack-bar-message="snackBarMessage"
    />
    <NetworkAlert :show-alert="showNetworkAlert"/>

    <v-main>
      <router-view/>
      <div class="bg-red-500">
      </div>
      <div class="feedback tw-fixed tw-bottom-10 lg:tw-bottom-0">
        <FeedBack  class=" tw-hidden lg:tw-block" v-if="userLoggedIn"/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SnackBar from "../src/components/UIComponents/SnackBar";
import FeedBack from "@/components/feedBack/FeedBack";
import NetworkAlert from "@/components/UIComponents/NetworkAlert";
// import Vue from "vue";
// import isOnline from "@/services/api/network";


export default {
  name: 'App',
  components:{NetworkAlert, FeedBack, SnackBar},
  computed: {
    ...mapGetters("snackBar", ["showSnackBar", "success", "snackBarMessage","showNetworkAlert"]),
    userLoggedIn(){
      return  !this.$route.meta.notProtected
    }  },

  data: () => ({
  }),
  created() {
    if(process.env.NODE_ENV === "production"){
      console.log = function () {};
    }


    // if(!isOnline()){
    //   Vue.prototype.$displayNetworkAlert(true)
    // }

  },
};
</script>
<style lang="scss">
.v-application{
  font-family: "DM Sans", serif;
}
h1, h2, h3, h4, h5, h6{
  font-family: IBM Plex Serif,serif;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
   border: 1px solid #9CABB5;
   box-sizing: border-box;
    border-radius: 6px;
  box-shadow: none !important;

&:before {
   display: none;
 }
}

.v-input--is-focused .v-input__slot {
  border: 1px solid #008EEF !important;
}

.v-input--is-disabled.v-text-field--solo .v-input__slot {
  background: #F7F9FC !important;
  border: 1px solid #D0DCE4 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
}

.v-label {
  font-family: DM Sans, serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #9CABB5 !important;
}
.v-btn--is-elevated{
  box-shadow: none;
}
.v-btn::before {
  background-color: transparent;
}
.v-btn__content {
  letter-spacing: 0em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
.text input::placeholder{
  font-family: DM Sans,serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  padding: 20px !important;
  color: #001343 !important;
}
.feedback{
  right: 10px;
  z-index: 9999;
  bottom: 0;
}


.v-overlay__scrim{
  background-color:#003D67 !important;
  opacity: 0.47;
  border-color: #003D67 !important;
  //will-change: initial !important;
}

.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 12px) !important;
  /* top: initial; */
}
.v-application ul {
  list-style-type: disc;
}
.v-application ol {
  list-style-type: auto;
}
.apex-label .apexcharts-legend-text{
  margin-left: 1.5rem !important;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}
.country-selector.lg .country-selector__label[data-v-46e105de] {
  display: none !important;
}
.country-selector.lg.has-value .country-selector__input[data-v-46e105de] {
  padding-top: 0 !important;
}
.input-tel__input[data-v-e59be3b4]::placeholder{
  display: none !important;
}
.country-selector.lg .country-selector__country-flag{
  top: 19px !important;
}
.input-tel.lg .input-tel__label{
  display: none;
}
.vue-phone-number-input.lg .select-country-container{
  margin-right: 10px;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  border-radius: 1px;
}
.input-tel.has-value .input-tel__input{
  padding-top: 0 !important;
}
.network .theme--light.v-icon {
  /* color: rgba(0, 0, 0, 0.54); */
  color: #FD2828 !important;
}
</style>
