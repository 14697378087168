<template>
  <div>
    <div class="applyFlexCenter tw-mb-12">
      <h2 class="underline">How Enum Recruit Works</h2>
    </div>
    <div class="tw-mb-32" v-for="stage in  stages" :key="stage.stage">
      <StageOne @dialog="$emit('dialog')" :item="stage">
        <template #description>
          <div class="tw-mb-4" v-for="desc in stage.description" :key="desc.boldText">
            <span><b>{{ desc.boldText }}</b>{{ desc.text }}</span>
          </div>
        </template>
      </StageOne>
    </div>

  </div>
</template>

<script>
import StageOne from "@/components/dashboard/components/HowItWorks/StageOne";

export default {
  name: "HowItWorksView",
  components: {StageOne},
  data() {
    return {
      stages: [
        {
          stage: 1,
          title: 'Create Job Opening',
          image: require('@/assets/create.svg'),
          description: [
            {
              boldText: 'Create or use custom job templates ',
              text: 'select assessment tests, set shortlisting criteria and post the job.'
            }
          ]
        },
        {
          stage: 2,
          title: 'Screen & Interview Candidates',
          isReverse:true,
          image: require('@/assets/screen.svg'),
          description: [
            {
              boldText: 'See only the best talent matches: ',
              text: 'We only show you the talent that best meets your criteria and whose personalities suit your organisation.'
            },
            {
              boldText: 'Ask the right questions: ',
              text: 'Get suggestions on what to ask candidates, before and during interview sessions.'
            }
          ]
        },
        {
          stage: 3,
          title: 'Hire the best',
          image: require('@/assets/hire.svg'),
          description: [
            {
              boldText: 'Make your decisions: ',
              text: 'Offer employment, reject candidates, or reserve them in your talent pool for future engagements.'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1E323F;
  @media (max-width: 700px) {
    font-size: 24px;
  }

}
.underline {
  background-image: linear-gradient(#0DFFC5, #0DFFC5);
  background-position: bottom center; /*Adjust the background-position to move the line*/
  background-size: 52% 4px; /*Adjust the background size to control length and height*/
  background-repeat: no-repeat;
  padding-bottom: 4px; /* this can also control the position */}


</style>