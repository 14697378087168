import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    talent_pool_id: "",
    all_talent_pool: [],
    all_candidates_in_a_talent_pool:[]
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
