<template>
  <div>
    <!--    <base-button :outlined="outlined" depressed :button-text="buttonText" @click="dialog = true"></base-button>-->
    <v-dialog
        v-model="dialog"
        max-width="550"
        persistent
        transition="dialog-top-transition"
    >
      <v-card class="tw-px-10 tw-py-7 cardBack">
        <div class="tw-flex tw-justify-end tw-mt-5">
          <v-btn icon text @click="closeModal">
            <v-icon color="black" class="ml-2">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="head mb-2">
            Sign Up For Early Access
          </span>
        </div>
        <div class="tw-mb-5">
          <span class="subText">
            Ready to try enum recruit? Please fill in your details<br/> to get access to the beta program.
          </span>
        </div>
        <div>
          <validation-observer ref="observer" v-slot="{handleSubmit}">
            <form ref="anyName" @submit.prevent="handleSubmit(submit)">
              <div class="width">
                <small>Name</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required">
                  <v-text-field
                      hide-details
                      v-model="corporate.name"
                      solo
                      id="name">
                  </v-text-field>
                  <span class="span" v-show="errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="width">
                <small class="tw-hidden md:tw-block tw--mb-1">Phone</small>
                <v-row >
                  <v-col cols="12" md="4" sm="4">
                    <small class="tw-block md:tw-hidden">Country Code</small>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Code"
                        rules="required">
                      <v-select solo v-model="num_code" :items="getCode" hide-details></v-select>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="8" sm="8">
                    <small class="tw-block md:tw-hidden">Number</small>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Number"
                        rules="required">
                      <v-text-field
                          hide-details
                          type="number"
                          v-model="number"
                          id="number"
                          solo>
                      </v-text-field>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div class="width">
                <small>Work Email</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Email Address"
                    rules="required|email">
                  <v-text-field
                      hide-details
                      v-model="corporate.work_email"
                      solo
                      @input="value => { corporate.work_email = value.toLowerCase();}"
                      id="email">
                  </v-text-field>
                  <span class="span" v-show="errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="width">
                <v-row>
                  <v-col cols="12" md="6" sm="6">
                    <small>Job Title</small>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Job Title"
                        rules="required">
                      <v-text-field
                          hide-details
                          v-model="corporate.job_title"
                          solo
                          id="job-title">
                      </v-text-field>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <small>Company Name</small>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Company Name"
                        rules="required">
                      <v-text-field
                          hide-details
                          v-model="corporate.company_name"
                          solo
                          id="company">
                      </v-text-field>
                      <span class="span" v-show="errors">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div class="width">
                <small>Are you hiring</small>
                <div class="lg:tw-w-full tw-flex tw-flex-wrap">
                  <div
                      v-for="hire in hiring"
                      :id="hire"
                      class="tw-mr-3 tw-mt-1"
                      :class="[hire === select ? 'selected': 'select']"
                      :key="hire"
                      @click="handleSelectedEducation(hire)"
                  >
                    {{hire}}
                  </div>
                </div>
              </div>
              <BaseButton class="mb-5" type="submit" :loading="loading" height="54px" id="register" width="210px" button-text="Get Early Access Now"/>
            </form>
          </validation-observer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NumberCode from "@/JsonFiles/NumberCode";
import BaseButton from "@/components/UIComponents/BaseButton";
import {sendWaitingList} from "@/services/api/APIService";

export default {
  name: "WaitingListForm",
  components: {BaseButton},
  data() {
    return {
      loading: false,
      num_code: '+234',
      number: '',
      hiring : ['Yes', 'No'],
      select: '',
      corporate: {
        name: '',
        job_title: '',
        phone_number: '',
        work_email: '',
        company_name: '',
        is_hiring: '',
      }
    }
  },
  props: {
    dialog:{
      type:Boolean,
      default:false
    },
  },
  computed: {
    getCode() {
      return NumberCode.number.map(item => item.code)
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
      this.select = ''
      this.$refs.anyName.reset(); // This will clear that form
    },
    handleSelectedEducation(hire){
      this.corporate.is_hiring = hire
      return this.select = hire
    },
    submit() {
      this.loading = true
      this.corporate.phone_number = this.num_code + this.number
      const data = this.corporate
      sendWaitingList(data).then(
          res => {
            this.$displaySnackbar({
              message: res.data.detail,
              success: true
            })
            this.loading = false
            this.closeModal()
          }
      ).catch( e=> {
          this.$displaySnackbar({
            message: e.response.data.detail,
            success: false
          })
          this.loading = false
      }
      )
    }
  }
}
</script>

<style scoped>
.cardBack {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px !important;
}
.head {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.05em;

  color: #1E323F
}
.subText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 135%;
  color: #1E323F;
}
.width {
  margin-bottom: 25px;
}
.span {
  font-family: DM Sans serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #FF2E2E;
}
.select{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}

.select-web{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select-web:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}


.selected{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;
}
</style>