import {
    editCompanyProfile, deleteCompanyFaq, getCompanyFaqs, getCompanyAwards, getReviews, createFAQ, createAward,
    updateSingleCompanyFaq, updateSingleCompanyAward, deleteCompanyAward,
    getCompanyAdmins, getCompanyProfile, getCompanyDescriptionAndMedia, getReviewInsight, getReviewsPaginated
} from "@/services/api/APIService";
import {viewFile} from "@/services/storage/aws";

export default {
    editCompanyProfile({commit}, data) {
        return editCompanyProfile(data.data, data.site_name).then(
            res => {
                commit('setEditCompanyProfile', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },
    async fetchReview({commit}, payload) {
        commit('SET_LOADING', true)
        await getReviews(payload).then(res => {
            commit('SET_REVIEW', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },

    async fetchRatingInsight({commit}, payload) {
        await getReviewInsight(payload).then(res => {
            console.log(res)
            commit('SET_REVIEW_INSIGHT', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    async fetchPaginatedReview({commit}, payload) {
        await getReviewsPaginated(payload).then(res => {
            console.log(res)
            commit('SET_REVIEW', res.data)
        })
            .catch(err => {
                this.$displaySnackbar({message: err.response.data.message, success: false})
                console.log(err)
            })
    },
    getCompanyProfile({commit}, site_name) {
        return getCompanyProfile(site_name).then(
            async ({ data }) => {
                const IMAGE_PROPERTIES = ['company_logo', 'cover_image']
                for (const property of IMAGE_PROPERTIES) {
                    await viewFile({ url: data[property], context: data, property })
                }
                commit('setCompanyProfile', data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            })
    },

    getCompanyAdmins({commit}, site_name) {
        return getCompanyAdmins(site_name).then(
            res => {
                commit('setCompanyAdmin', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },

    async getDescriptionAndMedia({commit,dispatch}, site_name) {
        commit('SET_LOADING', true)
        try {
            const {data: description} = await getCompanyDescriptionAndMedia(site_name)
            commit('getDescriptionAndMediaSuccess', description)
            JSON.stringify(sessionStorage.setItem('site_name', site_name))
            await dispatch('fetchReview', description.id)
            await dispatch('fetchRatingInsight', description.id)
            await dispatch('getCompanyFaqs', site_name)
            await dispatch('getCompanyAwards', site_name)


        } catch (e) {
            console.log(e)
        }
        finally {
            commit('SET_LOADING', false)
        }
    },
    async getCompanyFaqs({commit}, site_name) {
        return getCompanyFaqs(site_name).then(
            res => {
                commit('getCompanyFaqsSuccess', res.data)
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },
    createFaq({commit}, {site_name, data}) {
        return createFAQ({site_name,data}).then(
            res => {
                commit('updateFAQ', res.data)
                return Promise.resolve()
            })
    },
    updateSingleCompanyFaq({commit}, {faq_id, data}) {
        return updateSingleCompanyFaq({faq_id, data}).then(
            res => {
                commit('updateSingleFaqSuccess', res.data)
                return Promise.resolve()
            })
    },
    deleteCompanyFaq({commit}, faq_id) {
        return deleteCompanyFaq(faq_id).then(
            res => {
                commit('deleteCompanyFaqSuccess', faq_id)
                console.log(res)
                return Promise.resolve()
            })
    },
    async getCompanyAwards({commit}, site_name) {
        return getCompanyAwards(site_name).then(
            res => {
                res.data.forEach(award => {
                    award.openMenu = false
                    viewFile({
                        url: award.image,
                        context: award,
                    })
                })
                commit('getCompanyAwardsSuccess', res.data)
                return Promise.resolve()
            })
    },
    createAward({commit}, {site_name, data}) {
        return createAward({site_name,data}).then(
            res => {
                commit('updateAward', res.data)
                return Promise.resolve()
            })
    },
    updateSingleCompanyAward({commit}, {award_id, data}) {
        return updateSingleCompanyAward({award_id, data}).then(
            res => {
                commit('updateSingleCompanyAwardSuccess', res.data)
                return Promise.resolve()
            })
    },
    deleteCompanyAward({commit}, award_id) {
        return deleteCompanyAward(award_id).then(
            res => {
                commit('deleteCompanyAwardSuccess', award_id)
                console.log(res)
                return Promise.resolve()
            })
    },

}