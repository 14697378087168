import mutations from "@/store/jobScreeningCriteria/mutations";
import actions from "@/store/jobScreeningCriteria/actions";
import getters from "@/store/jobScreeningCriteria/getters";

// import {v4 as uuid} from "uuid";
export default {
    state: {
        questions: {
            "Visual Design": [
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
            ],
            "User Research": [
                {
                    title: "Introduction to User Research",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Importance of Research and Development",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
                {
                    title: "Introduction to Visual Design",
                    number_of_questions: 30,
                    duration: "30 mins"
                },
            ]
        },
        criteriaTopicsInView: {
            title: '',
            items: []
        },
        showQuestionCards: false,
        sampleQuestions: [
            // {
            //     id: uuid(),
            //     question: "The following is not an element of design?",
            //     options: ["Line", "Shape", "Mixture", "Unity"],
            //     answer: ["Shape", "Mixture"],
            //     explanation: "the elements of design are the fundamental aspect of any visual design which includes all except mixture",
            //     expand: false
            // }
        ],
        showSampleQuestions: false,
        fetchingCriteriaTopics: false,
        externalSkills: []
    },
    namespaced: true,
    mutations,
    actions,
    getters
}
