<template>
  <div id="background" class=" tw-w-full tw-bg-no-repeat  errorPage">


  <div class="tw-flex tw-justify-center tw-flex-col tw-h-4/5 tw-items-center tw-pt-10" >
    <div class="tw-pr-5 tw-pl-5 tw-w-20">
      <img :src=image alt=""/>
    </div>
    <h1 class="message tw-text-2xl tw-leading-60 md:tw-text-4xl	">Honestly, this one is on us.</h1>
    <p class="description text-sm md:tw-w-1/3 tw-pl-5 tw-pr-3">Please be patient. Our servers are currently down and we are working hard at bringing them back up for you.</p>
    <BaseButton id="okayBtn" width="100px" height="40px" block button-text="Okay" class="tw-mt-15 tw-mb-4"
                depressed type="submit"/>
  </div>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
  name: "ErrorPage505",
  components: {BaseButton},
  data() {
    return {
      image: require('../../../../assets/image.svg'),
      bgImage: require('../../../../assets/errDesign.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.errorPage{
  background-image: url('../../../../assets/errDesign.svg')!important;
  background-repeat: no-repeat;
  height: calc(100vh - 64px);
  background-size:contain;
  object-fit: cover;
}
.message{
  font-family: IBM Plex Serif;
  font-weight: 500;
  color: #9CABB5;
  text-align: center;
  line-height:60px
}
.description {
  font-family: DM Sans;
  font-weight: lighter;
  font-size: 14px;
  color: #9CABB5;
  align-items: center;
  text-align:center;
}

@media (max-width: 991px) {
  .errorPage {
    background-size: cover !important;
  }
}
</style>