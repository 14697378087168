import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const initialState = {
    userProfile: {},
    makeOffer: {},
    questionnaire_response: [],
    enrollment_response: [],
    get_Offer_state: {},
    rejectOffer: {},
    paymentDetails:{},
    allInteractionComment: [],
    // activity_log:[],
    // get_RejectOffer: {}
};

export default {
    state: initialState,
    mutations,
    actions,
    getters
};
