import {login, logout} from "@/services/api/AuthApiService";

export default {
    async userLogin({commit}, user) {
        return login(user).then(
            user => {
                commit("loginSuccess", user);
                return Promise.resolve(JSON.parse(user));
            },
            error => {
                commit("loginFailure");
                return Promise.reject(error);
            }
        );
    },
    async userLogOut() {
        console.log("out")
        logout();
    },


}

