// import axios from "axios";
// import authHeader from "@/services/api/auth-header";
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";

const {
    // GET,
    POST,
    // PUT,
    // PATCH,
    // DELETE
} = METHODS

let AUTH_URL;

if (process.env.NODE_ENV === "development") {
    AUTH_URL = process.env.VUE_APP_DEV_AUTH_URL;
} else {
    AUTH_URL = process.env.VUE_APP_PROD_AUTH_URL;

}

const authServiceCallEndpoint = ({method, url, data, needsAuth}) => {
    return callEndpoint({method, url, data, needsAuth}, AUTH_URL)
}

export const login = async user => {
    const url = '/auth/token/jwt'
    const {data} = await authServiceCallEndpoint({method: POST, data: user, url})
    const access = data.access
    const base64Url = access.split('.')[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64)
        .split("")
        .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    sessionStorage.setItem("userToken", JSON.stringify(data));
    return jsonPayload
}

export const logout = () => {
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("selectedDetails");
    sessionStorage.removeItem("returnedSelectedDetails");
    window.location.href = `${process.env.VUE_APP_FRONTEND_AUTH_URL}/auth/login?site=corporate`;
};


export const signUp = data => {
    const url = '/auth/accounts/register/'
    return authServiceCallEndpoint({method: POST, url, data})
}
export const verifyEmail = data => {
    const url = '/auth/accounts/verify-registration/'
    return authServiceCallEndpoint({method: POST, url, data})
}
export const resetPassword = data => {
    const url = '/auth/accounts/send-reset-password-link/'
    return authServiceCallEndpoint({method:POST, url, data})
};
export const changePassword = data => {
    const url = '/auth/accounts/reset-password/'
    return authServiceCallEndpoint({method:POST, url, data})
};
export const signUpNonExistingAdmin = data => {
    const url = '/auth/user/signup_recruitment_admin_to_company/'
    return authServiceCallEndpoint({method:POST, url, data})
}
