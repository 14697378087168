<template>
  <v-alert
      :value="showAlert"
      class="tw-my-8 tw-mx-4 md:tw-right-50	 network md:tw-w-96"
      icon="mdi-alert-outline"
      elevation="1"


  >
    <div class="text">We cannot detect internet connection. Seems like you are offline. <span @click="reloadPage" style="color:cornflowerblue;cursor:pointer;">Reload this page</span>.</div>
  </v-alert>
</template>

<script>
export default {
  name: "NetworkAlert",
  props: {
    showAlert: {
      type: Boolean,
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped>
.v-alert {
  position: fixed;
  z-index: 1000;
  /*left:50%*/
;  /*right: 3%;*/
  top: 8%;
}
.network{
  background: #FFFFFF;
  border: 1px solid #F3F7F8;
  box-sizing: border-box;
  box-shadow: 0px 2px 16px #F0F9FF;
  border-radius: 10px;
}
.text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */


  /* Enum-Grey/1 */

  color: #1E323F;
}

</style>