export default {
    setUserProfile: (state, data) => {
        state.userProfile = data
    },
    setMakeOffer:(state, data) => {
        state.makeOffer = data
    },
    setRejectOffer:(state, data) => {
        state.rejectOffer = data
    },
    set_get_Offer_State:(state, data) => {
        state.get_Offer_state = data
    },
    set_questionnaire_response:(state, data) => {
        state.questionnaire_response = data
    },
    set_enrollment_response:(state, data) => {
        state.questionnaire_response = data
    },
    setPaymentDetails:(state, data) =>{
        state.paymentDetails = data
    },
    set_allInteractionComment:(state, data) =>{
        state.allInteractionComment = data
    },
    removeComment: (state,data)=>{
        state.allInteractionComment = state.allInteractionComment.filter(element => data !== element.id)
    },
    // removeJob: (state, data) => (
    //     state.allAdminJobs = state.allAdminJobs.filter(element => data !== element.id)
    // )

 }
