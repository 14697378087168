<template>
  <div class="md:tw-ml-16 md:tw-mt-24 tw-mt-16 mb-5">
    <v-row>
      <v-col cols="12" md="5">
        <div class="tw-mx-5 md:tw-mx-0">
          <div :class="[$vuetify.breakpoint.mdAndDown? 'tw-mt-20':'tw-mt-40']">
            <span :class="[$vuetify.breakpoint.mdAndDown? 'tw-text-3xl':'tw-text-5xl']" class="head">Enum is Africa’s <br>No. 1 Talent Matchmaker</span>
          </div>
          <div class="tw-mt-8">
          <span class="sub-head lg:tw-text-waixl md:tw-text-xl tw-flex tw-hidden md:tw-block">Our end-to-end talent matching platform will<br> automatically sift through your applicants and match<br>
            you with the most suitable talent for your job roles.</span>
          </div>
          <div class="tw-mt-8">
          <span class="tw-flex tw-block md:tw-hidden sub-head lg:tw-text-2xl md:tw-text-xl text-wrap">Our end-to-end talent matching platform will automatically sift through your applicants and match
            you with the most suitable talent for your job roles.</span>
          </div>
          <div class="tw-mt-8 tw-flex tw-hidden md:tw-block">
            <v-btn x-large class="tw-rounded-lg" color="#008eef" @click="openDialog">
              <span class="btn-text text-capitalize">Sign Up</span>
            </v-btn>
          </div>
          <div class="tw-mt-8 tw-flex tw-block md:tw-hidden">
            <v-btn x-large class="tw-rounded-lg white--text text-capitalize" color="#008eef" block @click="openDialog">
              Sign Up
            </v-btn>
          </div>
          <div class="tw-mt-7">
            <div v-for="(item, index) in details" :key="index">
              <div class="d-flex my-4">
                <CheckIconGreen/>
                <span class="detail">{{item.name}}</span>
              </div>

            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <div class="tw-mx-5 md:tw-mx-0">
          <v-img :src="connectImage"></v-img>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CheckIconGreen from "@/components/UIComponents/reusablesIcon/CheckIconGreen";
export default {
  name: "LandingPageHeader",
  components: {CheckIconGreen},
  data() {
    return {
      connectImage: require('@/assets/EnumConnect.svg'),
      details: [
        {
          name: 'Collaborative hiring'
        },
        {
          name: '4x Faster Screening'
        },
        {
          name: 'Mobile Friendly'
        }
      ],
    }
  },
  methods: {
    openDialog() {
      this.$emit('dialog')
    }
  }
}
</script>

<style scoped>
.head {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.04em;
  color: #052942;

}
.sub-head{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  color: #5C6D79;

}
.btn-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: white;
}
.detail{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;

  color: #475661
}
</style>