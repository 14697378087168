<template>
  <div>
    <div class="tw-px-7 md:tw-px-16 lg:tw-mb-40 tw-mb-20">
      <div class="md:tw-mt-56 card tw-py-14 lg:tw-px-20 md:tw-px-12 tw-hidden md:tw-block">
        <div>
          <span class="head lg:tw-text-4xl md:tw-text-3xl">Try Enum Recruit Today</span>
        </div>
        <div class="tw-mt-2">
          <span class="sub-head lg:tw-text-2xl md:tw-text-xl">Enum is built to address your most pressing <br/>recruitment needs.</span>
        </div>
        <div class="tw-mt-7">
          <v-btn x-large class="tw-rounded-lg" @click="openDialog">
            <span class="btn-text text-capitalize">Sign Up</span>
          </v-btn>
        </div>
      </div>
      <div class="mobile-card tw-px-7 tw-py-5 tw-mt-28 mb-10 md:tw-hidden tw-block">
        <div>
          <span class="mobile-head">Try Enum <br/>Recruit Today</span>
        </div>
        <div class="tw-mt-2">
          <span class="mobile-sub">Enum is built to address your <br/>most pressing recruitment <br/>needs.</span>
        </div>
        <div class="tw-mt-7">
          <v-btn @click="openDialog" x-large class="tw-rounded-lg" block>
            <span class="btn-text text-capitalize">Sign Up</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="footer md:tw-px-20 tw-px-10 py-15">
      <div class="tw-mb-4">
        <EnumGreyLogo/>
      </div>
      <v-row>
        <v-col cols="12" md="8" sm="6">
          <div>
            <span class="footerText tw-hidden md:tw-block" style="color: #5C6D79;">Enum is Africa’s No 1. Talent matchmaker, and <br/> the recruiter’s best
              tool for hassle free, fast, <br/>cost-effective hiring of top performing talents.</span>
            <span class="footerText tw-block md:tw-hidden text-wrap" style="color: #5C6D79;">Enum is Africa’s No 1. Talent matchmaker, and the recruiter’s best
              tool for hassle free, fast, cost-effective hiring of top performing talents.</span>

          </div>
          <div>
            <a class="footerText" @click="openDialog"><u>Try Enum</u></a>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-row>
            <v-col cols="12" md="7" lg="6" sm="7">
              <span class="con">Contact</span>
              <div class="tw-flex">
                <MailIcon size="1.2x" class="mr-2"/>
                <span class="email">info@enum.africa</span>
              </div>
            </v-col>
            <v-col cols="12" md="5" lg="6" sm="5">
              <span class="con">Follow us</span>
              <div class="tw-flex tw-justify-start">
                <a class="mr-3" :href="'https://www.instagram.com/enumafrica/'" target="_blank"><v-img :src="instaImage"  aspect-ratio="1" width="17px"></v-img></a>
                <a :href="'https://www.twitter.com/enum_africa'" target="_blank"><v-img :src="twitterImage"  aspect-ratio="1" width="17px"></v-img></a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>

</template>

<script>
import EnumGreyLogo from "@/components/UIComponents/reusablesIcon/EnumGreyLogo";
import {MailIcon} from 'vue-feather-icons'
export default {
  name: "LandingPageFooter",
  components: { EnumGreyLogo, MailIcon},
  data() {
    return {
      dialog: false,
      instaImage: require('@/assets/insta.svg'),
      twitterImage: require('@/assets/twitter.svg'),
      emailImage: require('@/assets/email.png'),
      footerText: "Enum is Africa’s No 1. Talent matchmaker, and the recruiter’s best tool for hassle free, fast, \n cost-effective hiring of top performing talents."
    }
  },
  methods: {
    openDialog() {
      this.$emit('dialog')
    }
  }
}
</script>

<style scoped>
.card {
  height: 346px;
  background-image: url("../../../assets/footer-image-webview.png") !important;
  background-size: 100% 100%;

}
.mobile-card {
  height: 406px;
  background-image: url("../../../assets/footer-image-mobileview.png") !important;
  background-size: 100% 100%;
}
.head {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.05em;
  color: #FFFFFF;
}
.mobile-head {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #FFFFFF;
}
.sub-head {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}
.mobile-sub{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}
.btn-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #008eef;
}
.footer {
  background-color: #FAFAFA;
}
.footerText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
}
.email{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #5C6D79;
}
.con{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: rgba(92, 109, 121, 0.6);
}
</style>