import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const initialState = {
    allAdminJobs:[],
    allDraftJobs:[],
    allLiveJobs:[],
    allClosedJobs:[],
    jobDetail: {},
    listCandidates: []
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};