export default {
    getInterviewSchedule:(state)=>state.interviewScheduleDetails,
    getInterviewStatus:(state)=>state.interviewStatus,
    getInterviewComment:(state)=>state.allInterviewComment,
    getInterviewGrade:(state)=>state.interviewGrade,
    getInterviewQuestion: state => state.allInterviewQuestion,
    getProgramInterviewComment: state => state.allProgramInterviewComment,
    getLoading: (state)=>state.isLoading,
    get_comment_loading: (state)=>state.comment_loading,
    getInterviewId:(state)=>state.interviewId,
    getQuestionId: (state)=>state.questionId,
    getRecommendationComment: (state)=>state.recommendationComment


}
