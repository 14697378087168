import Vue from 'vue'
import VueRouter from 'vue-router'
// import SignUpOptions from "@/views/SignUpView/SignUpOptions";
import LandingPage from '@/views/LandingPage'
import ErrorPageView from "@/views/ErrorPageView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/signup/:type',
        name: 'RegistrationPage',
        component: () => import('../views/SignUpView/RegistrationPage'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/reset',
        name: 'Reset',
        component: () => import('../views/onboarding/Reset'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/onboarding/Change'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/verify-email',
        name: 'VerifyEmail',
        component: () => import('../views/onboarding/VerifyEmail'),
        meta: {
            notProtected: true,
        }
    },

    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
        meta: {
            notProtected: true,
        },
    },

    {
        path: '/toVerify',
        name: 'SignUpDone',
        component: () => import('../views/onboarding/VerifyNotification'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/job-posting/',
        name: 'JobPosting',
        component: () => import('../views/JobPosting.vue'),
        children: [
            {
                path: "1",
                name: "Job-stepOne",
                component: () => import('../components/jobPosting/components/StepOne.vue')
            },
            {
                path: "2",
                name: "Job-stepTwo",
                component: () => import('../components/jobPosting/components/StepTwo.vue')
            },
            {
                path: "3",
                name: "Job-stepThree",
                component: () => import('../components/jobPosting/components/StepThree.vue')
            },
            {
                path: "4",
                name: "Job-stepFour",
                component: () => import('../components/jobPosting/components/StepFour.vue')
            },
            {
                path: "5",
                name: "Job-stepFive",
                component: () => import('../components/jobPosting/components/StepFive.vue')
            },
            {
                path: "9",
                name: "Job-stepNine",
                component: () => import('../components/jobPosting/components/StepNine')
            },
            {
                path: "8",
                name: "Job-stepEight",
                component: () => import('../components/jobPosting/components/StepEight.vue')
            },
            {
                path: "6",
                name: "Job-stepSix",
                component: () => import('../components/jobPosting/components/StepSix.vue')
            },
            {
                path: 'shortlisting/:jobId',
                name: 'Shortlisting',
                component: () => import('../views/shortlisting/ShortlistingView'),
            },

            {
                path: "7",
                name: "Job-stepSeven",
                component: () => import('../components/jobPosting/components/StepSeven')
            },
        ]
    },

  {
        path: '/job-summary',
        name: 'JobSummary',
        component: () => import('@/views/JobSummary.vue')
    },
    {
        path: '/createProfile/:step',
        name: 'CreateCompanyProfileView',
        component: () => import( '@/views/createCompanyViews/CreateCompanyProfileView.vue'),
    },
    {
        path: '/all-organizations',
        name: 'ViewAllOrganizationPage',
        component: () => import('@/components/onboarding/components/viewAllOrganization/ViewAllOrganizationPage')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginPageView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/company-adminInvite/:step',
        name: 'InviteAnAdminView',
        component: () => import('../views/recruiterDashboardView/InviteAnAdminView'),
    },
    {
        path: '/:id/add-evaluation',
        name: 'EvaluationCriteria',
        component : () => import('../views/recruiterDashboardView/EvaluationCriteria')
    },
    {
        path: '/edit-profile/:tab',
        name: 'EditCompanyProfileView',
        component: () => import( '../views/recruiterDashboardView/EditCompanyProfileView'),
    },

    {
        path: '/edit-companySocial',
        name: 'EditCompanySocialView',
        component: () => import( '../views/recruiterDashboardView/EditCompanySocialView'),
    },

    {
        path: '/edit-companyAdmin',
        name: 'EditCompanyAdminView',
        component: () => import( '../views/recruiterDashboardView/EditCompanyAdminView'),
    },
    {
        path: '/candidate-profile',
        name: 'UserRedirect',
        component: () => import( '../views/UserRedirect'),
        meta: {
            notProtected: true,
        }
    },

    {
    path: '/candidateProfile',
    name: 'CandidateProfile',
    component: () => import('../views/CandidateProfileView'),
    redirect: {name:  'Evaluation'},
    children:[
        {
        path: "",
        name: 'Evaluation',
        component: () => import('../components/candidateProfile/candidatePageRoute/Evaluation')
        },
        {
            path: "interview",
            name: 'InterviewActivities',
            component: () => import('../components/candidateProfile/candidatePageRoute/InterviewActivities')
        },
        {
            path: "questionnaire",
            name: 'Questionnaire',
            component: () => import('../components/candidateProfile/candidatePageRoute/QuestionnaireResponse')
        },
        {
            path: "enrollment-form",
            name: 'Enrollment',
            component: () => import('../components/candidateProfile/candidatePageRoute/EnrollmentFormResponse')
        },
        {
            path: "activity-log",
            name: 'ActivityLog',
            component: () => import('../components/candidateProfile/candidatePageRoute/ActivityLog')
        },
        {
        path: "logs",
        name: 'Logs',
        component: () => import('../components/candidateProfile/candidatePageRoute/ActivityLogs')
    },

    ]
    },
    {
        path: '/dashboard',
        component: () => import( '@/views/recruiterDashboardView/RecruiterHomeView'),
        children: [
            {
                path: '',
                name: 'RecruiterDashboard',
                component: () => import( '@/components/dashboard/components/RecruiterHomeDashboard'),
                meta: {
                    dashboard: true,
                },
            },
            {
                path: 'candidate',
                name: 'RecruiterCandidateDashboard',
                component: () => import( '@/components/dashboard/components/RecruiterCandidateComponent'),
                meta: {
                    dashboard: true,
                },
            },
            {
                path: "jobs/candidates/:id",
                name: "AllCandidates",
                component: () =>
                    import(
                        "@/components/viewAdminJobs/AllCandidatesPage"
                        ),
                meta:{
                    isDashboard: false,
                    dashboard: true
                }
            },

            {
                path: 'jobs',
                name: 'JobSummaryOverview',
                component: () => import( '../components/viewAdminJobs/AllJobs'),
                meta: {
                    dashboard: true,
                },
            },
            {
                path: 'talent-pool',
                name: 'AllTalentPoolPage',
                component: () => import( '@/components/talentPool/AllTalentPoolPage'),
                meta: {
                    dashboard: true,
                },
            }
        ]
    },
    {
        path: "/job-screening-criteria",
        name: "JobScreeningCriteria",
        component: () => import('@/views/jobScreening/JobScreeningCriteriaView'),
        children: [
            {
                path: 'library/:id',
                name: 'TestLibrary',
                component: () => import('@/views/TestLibrary')
            },
            {
                path: '/saved/:id',
                name: 'SavedTests',
                component: () => import('@/views/SavedTests')
            }
        ]
    },
    {
        path: '/interviewSchedule/:step',
        name: 'InterviewScheduleView',
        component: () => import('@/views/interview/InterviewScheduleView'),

    },
    {
        path: '/how-it-works',
        name: 'HowItWorks',
        component: () => import('@/views/HowItWorksView'),

    },
    {
        path: '/interview',
        name: 'ConductInterviewView',
        component: () => import('@/views/interview/ConductInterviewView'),

    },
    {
        path: '/talent-pool-candidates/:talentPoolId',
        name: 'AllCandidateInATalentPool',
        component: () => import('@/components/talentPool/AllCandidateInATalentPool')
    },
 {
        path: '/uploadCandidates/:talentPoolId',
        name: 'UploadCandidatesPage',
        component: () => import('@/components/talentPool/UploadCandidatesPage')
    },
    {
        path: '/interview-report',
        name: 'InterviewReport',
        component: () => import('@/views/interview/InterviewReportView')
    },
    {
        path: '/screening/option',
        name: 'ScreeningOptionView',
        component: () => import('@/views/jobScreening/JobScreenOptionView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '/screening/questions',
        name: 'ScreeningQuestionAreaView',
        component: () => import('@/views/jobScreening/QuestionAreaView')
    },
    {
        path: '/interviewPage',
        name: 'InterviewPageView',
        component: () => import('@/views/interview/InterviewPage')
    },
    {
        path: '/errorPage',
        name: 'ErrorPage',
        component: () => import('../views/ErrorPageView'),
        meta: {
            notProtected: true,
        }
    },
    {
        path: '*',
        component: ErrorPageView,
        meta: {
            notProtected: true,
        }
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
            behavior: "smooth"
        };
    },
})
router.beforeEach((to, from, next) => {
    const isUserLogIn = JSON.parse(sessionStorage.getItem('userData'))
    const routeNotProtected = to.matched.some(record => record.meta.notProtected);
    if (!isUserLogIn && !routeNotProtected) {
        // Vue.prototype.$displaySnackbar({
        //     message: 'User not logged in, login try again later.',
        //     success: false
        // })

        next('/login')

    }




    else next()
})


export default router
