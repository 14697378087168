export default {
    loginSuccess: (state, user) => {
        state.status.loggedIn = true;
        // let users = JSON.parse(user)
        state.isPresent = true
        state.user = {...state.user, ...user}
        console.log(state.user)
        sessionStorage.setItem('userData', JSON.stringify(state.user))
        state.loading = false
    },
    loginFailure: (state) => {
        state.status.loggedIn = false;
        state.user = null;
        state.loading = false
        state.isPresent = false
    },
}