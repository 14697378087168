import actions from "./actions"
import mutations from "./mutations";
import getters from "@/store/shortlisting/getters";


const state = {
    fieldData: [
        {
            title: 'Age Range',
            type: 'age',
            enable: false,
            response: {
                age_upper_limit: null,
                age_lower_limit: null,
            },
        },
        {
            title: 'Years of Experience',
            type: 'years_of_experience',
            enable: false,
            response: {
                years_of_experience: null,
                experience:null
            },
        },
        {
            title: 'Highest Level of Education',
            type: 'highest_level_of_education',
            enable: false,
            response: {
                highest_level_of_education: '',
            },
        },
        {
            title: 'Location',
            type: 'location',
            enable: false,
            response: {
                country: "",
                state: "",
                city: "",
                is_remote: false
            },
        },
        {
            title: 'Certification',
            type: 'certification',
            enable: false,
            response: {
                certification: []
            },
        },
        {
            title: 'Salary Range',
            type: 'salary',
            enable: false,
            response: {
                currency: "",
                salary_range: null,
                salary_upper_limit: null,
                salary_lower_limit: null,
            },
        },

    ],
    criteriaDetails: {},
    jobInfo: {},
    otherCriterias: [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
