import {getAllAdminJobs, getAllDraftJobs, getAllClosedJobs, getAllLiveJobs, jobDetails, listAllCandidates, deleteJob} from "@/services/api/APIService";

export default {
    async deleteSingleJob({commit}, data) {
        await deleteJob(data).then(
            () => {
                commit('removeJob', data)
            }
        )
    },
    getAllAdminJobs({commit}, data){
        return getAllAdminJobs(data).then(
            res => {
                commit("getAllAdminJobSuccess", res.data);
                return Promise.resolve(res.data);
            },
            error => {
                commit("getAllAdminJobFailure");
                return Promise.reject(error);
            }
        );
    },
    getAllDraftJobs({commit}, data){
        return getAllDraftJobs(data).then(
            res => {
                commit("getAllDraftJobSuccess", res.data);
                return Promise.resolve(res.data);
            },
            error => {
                commit("getAllDraftJobFailure");
                return Promise.reject(error);
            }
        );
    },
    getAllClosedJobs({commit}, data){
        return getAllClosedJobs(data).then(
            res => {
                commit("getAllClosedJobSuccess", res.data);
                return Promise.resolve(res.data);
            },
            error => {
                commit("getAllClosedJobFailure");
                return Promise.reject(error);
            }
        );
    },
    getAllLiveJobs({commit}, data){
        return getAllLiveJobs(data).then(
            res => {
                commit("getAllLiveJobSuccess", res.data);
                return Promise.resolve(res.data);
            },
            error => {
                commit("getAllLiveJobFailure");
                return Promise.reject(error);
            }
        );
    },
    getAllJobDetails({commit}, payload) {
        return jobDetails(payload).then(
            res => {
                commit('getJobDetailsSuccess', res.data);
                return Promise.resolve(res.data);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    getAllListCandidates({commit}, data) {
        return listAllCandidates(data).then(
            res => {
                commit('getLiveCandidatesSuccess', res.data.map(d => ({...d, openMenu: false})));
                return Promise.resolve(res.data)
            },
            error => {
                console.log(error)
                return Promise.reject(error);
            }
        )
    }
};
