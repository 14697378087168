import {weeklyInsights} from "@/services/api/APIService";




export default {

    async weeklyDashboard({commit}, data) {
        return weeklyInsights(data).then(res => {
            console.log(res.data,'week week')
            commit('setWeeklyInsight', res.data)})},

}