import Vue from 'vue'
import Vuex from 'vuex'
import snackBar from './snackBar'
import onBoarding from './onBoarding'
import adminJobs from './adminJobs'
import jobPosting from './jobPosting'
import shortlisting from './shortlisting'
import jobScreeningCriteria from '@/store/jobScreeningCriteria'
import feedBack from './feedBack'
import interview from './interview'
import editCompany from './editCompany'
import talentPool from "./talentPool"
import candidateProfile from "./candidateProfile"
import dashboardInsights from "./dashboardInsights"




Vue.use(Vuex)

export default new Vuex.Store({
modules:{
  snackBar,
  onBoarding,
  jobPosting,
  shortlisting,
  adminJobs,
  jobScreeningCriteria,
  feedBack,
  interview,
  editCompany,
  talentPool,
  candidateProfile,
  dashboardInsights

}
})
