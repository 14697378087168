<template>
  <div id="background" class=" tw-w-full tw-bg-no-repeat errorPage">


<!--   <div class="tw-px-5 errorPage" >-->

    <div class="tw-flex tw-justify-center tw-flex-col tw-h-4/5 tw-items-center tw-pt-10" >
      <div class="magnifier pt-9 tw-w-20"  >
        <img sizes="2" :src=image alt=""/>
      </div>
      <h1 class="errorMessage">We couldn't find the page.</h1>
      <p class="description text-sm md:tw-w-1/3 tw-pl-6 tw-pr-3">The page you are looking for may have been deleted, moved or does not exist.
        But we can take you back to safety.</p>
      <BaseButton id="backBtn" width="100px" height="40px" @click="$router.go(-1)" block button-text="Go back" class="tw-mt-15 tw-mb-4"
                  depressed type="submit"/>
    </div>
      </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
  name: "ErrorPage404",
  components: {BaseButton},
  data() {
    return {
      image: require('../../../../assets/404.svg'),
      bgImage: require('../../../../assets/errDesign.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.errorPage {
  background-image: url('../../../../assets/errDesign.svg')!important;
  background-repeat: no-repeat;
  height: calc(100vh - 64px);
  background-position: 40px 0px;
  background-size: contain;
  object-fit: cover;
  @media (max-width: 991px) {
      background-size: cover !important;
    }
  }

.errorMessage{
  font-size:29px ;
  font-family: IBM Plex Serif;
  font-weight: 500;
  color: #9CABB5;
  line-height: 60px;
  text-align: center;
}
.description {
  font-family: DM Sans;
  font-weight: lighter;
  font-size: 14px;
  color: #9CABB5;
  align-items: center;
  text-align:center;
}

  </style>