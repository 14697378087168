<template>
  <svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.583 30.2893C29.4475 28.9337 27.4381 28.7842 25.9048 29.6652C22.9258 31.3768 19.5025 32.2224 16.0268 32.0691C12.551 31.9159 9.21543 30.7723 6.39863 28.8052C4.94882 27.7927 2.93399 27.7648 1.68357 29.0152C0.433153 30.2656 0.422281 32.3124 1.82429 33.3901C5.79024 36.4387 10.6136 38.2228 15.6593 38.4628C15.6878 38.4641 15.7162 38.4654 15.7447 38.4667C15.7732 38.468 15.8016 38.4692 15.8301 38.4703C20.8773 38.6753 25.839 37.3226 30.0579 34.6349C31.5493 33.6847 31.7186 31.6449 30.583 30.2893Z" fill="#5C6D79" fill-opacity="0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8269 7.62327C13.377 7.62327 10.5803 10.42 10.5803 13.8699C10.5803 17.3198 13.377 20.1165 16.8269 20.1165C20.2768 20.1165 23.0735 17.3198 23.0735 13.8699C23.0735 10.42 20.2768 7.62327 16.8269 7.62327ZM16.8269 27.7398C24.487 27.7398 30.6968 21.53 30.6968 13.8699C30.6968 6.20976 24.487 0 16.8269 0C9.16679 0 2.95703 6.20976 2.95703 13.8699C2.95703 21.53 9.16679 27.7398 16.8269 27.7398Z" fill="#5C6D79" fill-opacity="0.4"/>
  </svg>

</template>

<script>
export default {
  name: "EnumGreyLogo"
}
</script>

<style scoped>

</style>