<template>
  <div class="wrapper  tw-h-full">
    <div style="height: 64px;">
      <slot name="header"></slot>
    </div>

    <div class="main__content">
      <slot name="main-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
name: "Onboardlayout"
}
</script>

<style scoped lang="scss">
.main__content {
  min-height: calc(100vh - 64px);
  overflow: hidden;
}
</style>
