import {
    createTalentPool,
    getAllCandidatesInATalentPool,
    getAllTalentPool,
    moveCandidateToATalentPool
} from "@/services/api/APIService";

export default {
    createTalentPool({ commit }, data) {
        return createTalentPool(data).then(
            res => {
                console.log(res)
                commit("updateTalentPool", res.data);
                commit("getTalentPoolID", res.data.id);
                return Promise.resolve(res.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getAllTalentPool({ commit }, data) {
        return getAllTalentPool(data).then(
            res => {
                let allTalentPool = res.data.sort(
                    (a, b) => new Date(b.date_created) - new Date(a.date_created)
                );
                commit("getAllTalentPool", allTalentPool);
                console.log(res.data, "from store");
                return Promise.resolve(res.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getAllCandidatesInATalentPool({commit}, {site_name, pool_id}){
        return getAllCandidatesInATalentPool({site_name, pool_id})
            .then(res => {
                console.log(res)
                commit("allCandidatesInATalentPool", res.data);
            })
            .catch(err => {
                console.log(err);
            });
    },
    moveCandidateToATalentPool({ commit }, data) {
        return moveCandidateToATalentPool(data)
            .then(res => {
                console.log(res)
                console.log(data.email)
                commit("moveCandidateToATalentPoolSuccessful", data.email);
            })

    }
}