import moment from "moment";

export default {
  methods:{
    handleLike(data) {
      this.$store.commit('post/toggleLike',data)
      return this.$store.dispatch('post/likeCommentOrPost', data.id)
    },
    findItemById(items, id) {
      return items.find((item) => item.id === id);
    },
    removeEmpty(obj) {
      const result = Object.entries(obj)
          // eslint-disable-next-line no-unused-vars
        .filter(([_, value]) => !!value || typeof value === "boolean")
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
      return result
    },
    shortAm(file_name, len) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, len)
    },
    convertDate(val) {
      return moment(val).format('ll . ha')
    },
    likeColor(m) {
      return m.is_liked ? '#FF5353' : ''
    },
    convertNumber(x) {
      if (!x) return
      else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
